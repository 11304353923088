import React, { useState } from "react";
import { IoIosClose } from "react-icons/io";
import InputField from "./inputField";
import Button from "./button";
import ModalHeader from "./modalHeader";
import { Camera01Icon } from "hugeicons-react";
import useCameraScanner from "../utils/customhooks/CameraScanner";
import Webcam from "react-webcam";

const AddService = ({ closeModal }) => {
  const {
    isCameraOpen,
    toggleCamera,
    webcamRef,
    captureImage,
    isProcessing,
    scannedText,
  } = useCameraScanner();
  const [statusMethod, setStatusMethod] = useState("");
  const [reminderMethod, setReminderMethod] = useState("");
  const statusOptions =[
    { label: "Processing", value: "Processing" },
    { label: "Completed", value: "Completed" },
    { label: "Created", value: "Created" },
  ];
  const reminderOptions = [
    { label: "Everyday", value: "Everyday" },
    { label: "3 months", value: "3 months" },
    { label: "6 months", value: "6 months" },
  ];
  const partsUsedOptions = [
    { label: "Oil Change", value: "Oil Change" },
    { label: "Battery Repair", value: "Battery Repair" },
    { label: "Tire Change", value: "Tire Change" },
  ];
 
  const [selectedPartsOption, setSelectedPartsOption] = useState([]);
  const [dateValue, setDateValue] = useState("");
  const [collectionDateValue, setCollectionDateValue] = useState("");

  const handlePartsValueChange = (newValue) => {
    const selected = partsUsedOptions.find((option) => option.value === newValue);
    
    if (selected && !selectedPartsOption.find((option) => option.value === newValue)) {
      setSelectedPartsOption((prevSelected) => [...prevSelected, selected]);
    }
  };
  

  const handleDeleteSelected = (valueToRemove) => {
    setSelectedPartsOption((prevSelected) =>
      prevSelected.filter((option) => option.value !== valueToRemove)
    );
  };
  const [mileage, setMileage] = useState("");
 
   React.useEffect(() => {
     if (scannedText) {
       setMileage(scannedText);
     }
   }, [scannedText]);

  return (
    <>
    <div
      className="fixed inset-0 z-40 flex items-center justify-center md:items-start md:justify-end bg-black bg-opacity-40 font-dmsans"
      onClick={closeModal}
    >
      <div
        className="bg-white shadow-lg w-full md:w-[60vw] rounded-l-[10px] h-[90%] md:h-full overflow-y-auto scrollbar-thin scrollbar-thumb-scrollbar-thumb hover:scrollbar-thumb-scrollbar-thumb-hover  scroll-smooth flex flex-col gap-8"
        onClick={(e) => e.stopPropagation()}
      >
        <ModalHeader
          header="Add New Service"
          onClick={closeModal}
          icon={<IoIosClose className="w-full h-full" />}
        />

        <form className="flex flex-col gap-[26px] px-6 md:px-[60px] w-full pt-[20px] md:mt-0 bg-white">
          {/* Customer Name */}
          <InputField
            label="Customer Name"
            name="customerName"
            type="text"
            asteriks={true}
            placeholder="Enter name"
            className="w-full flex flex-col gap-2"
            inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px]"
          />
          <div className="flex flex-col md:flex-row gap-[12px] w-full items-center md:gap-[20px]">
            <InputField
              asteriks={true}
              label="Date"
              type="calendar"
              name="date"
              value={dateValue}
              handleValue={setDateValue}
              placeholder="Select a date"
              calendarClassName="w-full"
              className="w-full"
            />
            <InputField
              label="Service"
              name="service"
              type="text"
              asteriks={true}
              placeholder="e.g Car repair"
              className="w-full flex flex-col gap-2"
              inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px]"
            />
          </div>
          <div className="flex flex-col md:flex-row gap-[12px] w-full items-center md:gap-[20px]">
             <div className="flex flex-col gap-[7px] w-full">
                          <div className="flex gap-2">
                            {" "}
                            <label className="font-[400] text-[15px] leading-[19px] text-black">
                              Mileage
                              <span className="text-[#FF0000]">*</span>
                            </label>
                          </div>
          
                          <div className="relative w-full  flex items-center">
                            <input
                              type="text"
                              value={mileage}
                              onChange={(e) => setMileage(e.target.value)}
                              placeholder="e.g ABC-1234"
                              className="w-full h-[58px] lg:h-[69px] rounded-[12px] lg:rounded-[13px] bg-white py-4 px-[14px] border border-[#E7E7E7] outline-[#00325E]"
                            />
                            <span
                              className="absolute right-3 cursor-pointer"
                              onClick={toggleCamera}
                            >
                              <Camera01Icon className="w-[24px] h-[24px]" />
                            </span>
                          </div>
                        </div>
            {/* <InputField
              label="Mileage"
              name="mileage"
              type="text"
              asteriks={true}
              placeholder="e.g 30,000 miles"
              className="w-full flex flex-col gap-2"
              inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px]"
              postfix={<Camera01Icon className="w-[24px] h-24px]"/>}
            /> */}
            <InputField
              label="Cost"
              name="cost"
              type="text"
              asteriks={true}
              placeholder="e.g $100"
              className="w-full flex flex-col gap-2"
              inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px]"
            />
          </div>
          <InputField
            label="Notes"
            asteriks={true}
            name="businessDescription"
            type="textarea"
            placeholder="For internal use of taking notes about the customer issues"
            className="w-full flex flex-col gap-2"
            inputClassName="w-full rounded-[12px] md:rounded-[13px] "
          />
          <div className="flex flex-col md:flex-row gap-[12px] w-full items-center md:gap-[20px]">
            <InputField
              asteriks={true}
              label="Date of Collection"
              type="calendar"
              name="date"
              value={collectionDateValue}
              handleValue={setCollectionDateValue}
              placeholder="Select a date"
              calendarClassName="w-full"
              className="w-full"
            />
            <InputField
              label="Status"
              asteriks={true}
              name="status"
              type="select"
              options={statusOptions}
              placeholder="Select Status"
              className="w-full flex flex-col gap-2"
              selectClassName="w-full flex justify-center items-center rounded-[12px] md:rounded-[13px] h-[58px] md:h-[69px]"
              handleValue={setStatusMethod}
              value={statusMethod}
            />
          </div>
          <div className="flex flex-col md:flex-row gap-[12px] w-full items-start md:gap-[20px]">
            <div className="flex flex-col gap-[7px] w-full">
              <InputField
                label="Parts Used"
                asteriks={true}
                name="partsUsed"
                type="select"
                searchable={true}
                options={partsUsedOptions.filter(
                    (option) =>
                      !selectedPartsOption.find((sel) => sel.value === option.value)
                  )}
                placeholder="Select Parts"
                className="w-full flex flex-col gap-2"
                selectClassName="w-full flex justify-center items-center rounded-[12px] md:rounded-[13px] h-[58px] md:h-[69px]"
                handleValue={handlePartsValueChange}
              />
       {/* Display the selected value */}
       <div className="flex flex-wrap gap-[18px]">
                  {selectedPartsOption.map((option) => (
                    <div
                      key={option.value}
                      className="mt-[8px] flex items-center"
                    >
                      <div className="h-[39px] w-auto py-[10px] px-[10px] bg-[#DBE2EF] rounded-[12px] flex gap-[10px] items-center">
                        <p className="text-[15px] leading-[19px] text-black font-[500] font-montserrat">
                          {option.label}
                        </p>
                        <button
                          className="text-[17px]"
                          onClick={() => handleDeleteSelected(option.value)}
                        >
                          x
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
            </div>
            <div className="flex flex-col gap-[7px] w-full">
              {/*Set Reminder (optional)  */}
              <InputField
                label="Set Reminder (optional) "
                asteriks={true}
                name="reminder"
                type="select"
                options={reminderOptions}
                placeholder="Set Reminder"
                className="w-full flex flex-col gap-2"
                selectClassName="w-full flex justify-center items-center rounded-[12px] md:rounded-[13px] h-[58px] md:h-[69px]"
                handleValue={setReminderMethod}
                value={reminderMethod}
              />
            </div>
          </div>
          <div className="flex  items-center justify-center md:justify-around my-8 md:mt-[60px] md:mb-[100px]">
            <Button
              type="secondary"
              borderRadius="rounded-[5px]"
              size="S"
              label="Cancel"
              onClick={closeModal}
              className="w-[119px] hidden lg:flex justify-center items-center"
            />
            <Button
              type="tertiary"
              borderRadius="rounded-[5px]"
              size="S"
              label="Submit"
              className="md:w-[186px] w-full"
            />
          </div>
        </form>
      </div>
    </div>
    {isCameraOpen && (
        <div className="fixed inset-0 z-40 flex justify-center items-center  bg-black bg-opacity-40 font-dmsans">
          <div className="bg-white rounded-[8px] w-full p-3 lg:w-[50vw] max-h-[90vh]">
            <div className="flex flex-col items-center gap-4">
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                className="w-full h-[300px] rounded-[13px] border border-[#E7E7E7]"
              />
              <Button
                type="primary"
                borderRadius="rounded-[8px]"
                size="M"
                label="Capture Photo"
                onClick={captureImage}
              />
              <Button
                type="secondary"
                borderRadius="rounded-[8px]"
                size="M"
                label="Cancel"
                onClick={toggleCamera}
              />
            </div>
          </div>
        </div>
      )}

      {isProcessing && (
        <div className="fixed inset-0 z-40 flex justify-center items-center  bg-black bg-opacity-40 font-dmsans">
          <p className="text-gray-500">Processing image...</p>
        </div>
      )}
    </>
  );
};

export default AddService;
