import React, { useState, useEffect, useCallback, useMemo } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import calendar from "../assets/images/solarcalendar.svg";
import search from "../assets/images/search.svg";
import Dropdown from "../components/dropdown";
import Table from "../components/Table";
import Pagination from "../components/pagination";
import Button from "../components/button";
import { Link } from "react-router-dom";
import { IoSearch } from "react-icons/io5";
import { BsDownload } from "react-icons/bs";
import DownloadDropdown from "../components/downloadDropdown";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import { fetchCustomers } from "../store/customerSlice";

const CustomerList = ({ openAddCustomerModal }) => {
  const dispatch = useDispatch();
  const pageSize = 10;
  // Access customers from Redux state
  const { customers, loading, error, totalPages } = useSelector(
    (state) => state.customer
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredCustomers, setFilteredCustomers] = useState(customers);
  const [selectedRange, setSelectedRange] = useState('All'); 
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  
  const today = useMemo(() => {
    return new Date();
  }, []);
  const getDateRange = useCallback(() => {
    if (selectedRange === "custom") {
      if (!startDate || !endDate) return { start: null, end: null }; // Prevents filtering if dates are missing
      return { start: new Date(startDate), end: new Date(endDate) };
    }
    switch (selectedRange) {
      case 'today':
        return { start: today, end: today };
      case 'yesterday':
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        return { start: yesterday, end: yesterday };
      case 'thisWeek':
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - today.getDay()); // Set to the start of the week (Sunday)
        return { start: startOfWeek, end: today };
      case 'thisMonth':
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        return { start: startOfMonth, end: today };
      case 'lastMonth':
        const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
        return { start: startOfLastMonth, end: endOfLastMonth };
      default:
        return { start: null, end: null };
    }
  }, [selectedRange, startDate, endDate, today]); // dependencies array

  const filterCustomersByDate = useCallback(() => {
    const { start, end } = getDateRange();
    
    if (!start || !end) {
      setFilteredCustomers(customers);
      return;
    }
  
    const filtered = customers.filter((customer) => {
      const customerDate = new Date(customer.createdAt);
      customerDate.setHours(0, 0, 0, 0); // Reset time to midnight for accurate date-only comparison
  
      const startDateOnly = new Date(start);
      startDateOnly.setHours(0, 0, 0, 0);
      
      const endDateOnly = new Date(end);
      endDateOnly.setHours(23, 59, 59, 999); // Allow full-day filtering
  
      return customerDate >= startDateOnly && customerDate <= endDateOnly;
    });
  
    setFilteredCustomers(filtered);
  }, [customers, getDateRange]);


  useEffect(() => {
    if (selectedRange === 'custom' && startDate && endDate) {
      filterCustomersByDate();
    }
  }, [selectedRange, startDate, endDate, filterCustomersByDate]);
  


  useEffect(() => {
    if (customers.length > 0) {
      setFilteredCustomers(customers);
      filterCustomersByDate(selectedRange);
    }
  }, [customers, selectedRange, filterCustomersByDate]);

 
  useEffect(() => {
    // Check if customers are already loaded
    if (!customers || customers.length === 0) {
      dispatch(fetchCustomers({ page: currentPage }));
    }
  }, [dispatch, currentPage, customers]);  // Make sure to include customers in the dependency array
  
  
  // const sortedCustomers = [...customers].sort(
  //   (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  // );

  const headers = [
    "S/N",
    "Customer ID",
    "Customer Name ",
    "Phone Number",
    "Start of Service",
    "Car Model",
    "Mileage",
  ];
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };
  const renderRow = (item, index, rowClass) => (
    <tr key={item.id} className={rowClass}>
      <td
        className={`border-b font-[400] text-[14px] leading-[21px] text-strokeColor px-6 py-4 my-2 text-left text-nowrap sticky left-0 z-20`}
        style={{
          backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
        }}
      >
        {index + 1 + (currentPage - 1) * pageSize} 
      </td>
      <td className="border-b font-[400] text-[14px] text-center leading-[21px] text-strokeColor text-nowrap px-4 lg:py-4 my-2">
        {item.id}
      </td>
      <td className="lg:py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
        {item.name}
      </td>
      <td className="lg:py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
        {item.phone}
      </td>
      <td className="lg:py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
        {new Intl.DateTimeFormat("en-GB", {
          year: "numeric",
          month: "short",
          day: "2-digit",
        }).format(new Date(item.createdAt))}
      </td>
      <td className="lg:py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
        {item.carModel}
      </td>
      <td className="lg:py-4 text-center border-b text-nowrap">
        <div className="flex justify-center">
          <Link to={`/customers/list/details/${item.id}`}>{item.mileage}</Link>
        </div>
      </td>
    </tr>
  );

  const handleOpenAddCustomerModal = () => {
    openAddCustomerModal();
  };

  // State for dropdown visibility
  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
  // const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [isDownloadDropdownOpen, setIsDownloadDropdownOpen] = useState(false);
  // Dropdown actions
  const dateActions = [
    { label: "All date", onClick: () => setSelectedRange('all') },
    { label: "Custom", onClick: () => setSelectedRange('custom') },
    { label: "Today", onClick: () => setSelectedRange('today') },
    { label: "Yesterday", onClick: () => setSelectedRange('yesterday')},
    { label: "This Week", onClick: () => setSelectedRange('thisWeek') },
    { label: "This Month", onClick: () => setSelectedRange('thisMonth') },
    { label: "Last Month", onClick: () => setSelectedRange('lastMonth') },
  ];

  // const statusActions = [
  //   { label: "Completed", onClick: () => console.log("Filter: Completed") },
  //   { label: "In-progress", onClick: () => console.log("Filter: In-progress") },
  //   { label: "Pending", onClick: () => console.log("Filter: Pending") },
  // ];

  const downloadActions = [
    { label: "Download as PDF", onClick: () => console.log("Download as PDF") },
    {
      label: "Download as Plain Text",
      onClick: () => console.log("Download as Plain Text"),
    },
    {
      label: "Download as Microsoft word",
      onClick: () => console.log("Download as Microsoft word"),
    },
  ];
  const downloadMobileActions = [
    { label: "PDF", onClick: () => console.log("Download as PDF") },
    {
      label: "Plain Text",
      onClick: () => console.log("Download as Plain Text"),
    },
    {
      label: "Microsoft word",
      onClick: () => console.log("Download as Microsoft word"),
    },
  ];

  return (
    <HelmetProvider>
      <Helmet>
        <title>Hamzaa Merchant | Customer List</title>
        <meta name="description" content="Hamzaa Customer List page" />
      </Helmet>
      <div className="lg:absolute lg:top-[90px] w-full lg:w-[calc(100%-319px)] pt-4 px-6 z-10 lg:px-[48px] pb-[40px] min-h-[100vh] font-dmsans bg-white lg:bg-[#F8FAFF] ">
        <div className="mt-[100px] lg:mt-[50px] flex gap-5 lg:gap-0 lg:justify-between items-center">
          <Button
            onClick={handleOpenAddCustomerModal}
            type="tertiary"
            borderRadius="rounded-[5px]"
            size="XS"
            label="Add new customer"
            className="w-full lg:w-auto font-[500]"
            iconLeft="+"
            iconLeftClassName="text-[22px] leading-[21px]"
          />
          <div className="relative">
            <button
              onClick={() => setIsDownloadDropdownOpen((prev) => !prev)}
              className="flex justify-between lg:justify-center items-center gap-1 border-[1px] border-[#D9D9D9] w-full lg:w-auto h-[50px] rounded-[5px] py-[4px] px-[8px] shadow-sm bg-[#FAFDFF]"
            >
              <div className="block lg:hidden">
                <BsDownload className="text-[#1C1C1C] w-4 h-4" />
              </div>
              <div className="flex items-center gap-8 lg:gap-1">
                <span className="text-center text-[#1C1C1C]">
                  <span className="md:block hidden font-dmsans font-[400] text-[12px] leading-[18px]">
                    {" "}
                    Download{" "}
                  </span>
                  {/* <span className="block lg:hidden font-montserrat font-[400] text-[14px] leading-[21px] ">PDF</span> */}
                </span>
                <button className="flex justify-center items-center">
                  {isDownloadDropdownOpen ? (
                    <IoIosArrowUp className="text-[#1C1C1E] w-[16px] h-[16px]" />
                  ) : (
                    <IoIosArrowDown className="text-[#1C1C1E] w-[16px] h-[16px]" />
                  )}
                </button>
              </div>
            </button>
            <div className="hidden md:block">
              {isDownloadDropdownOpen && (
                <DownloadDropdown
                  actions={downloadActions}
                  onClose={() => setIsDownloadDropdownOpen(false)}
                  position="top-[60px] right-[10px]"
                  searchable={false}
                  width="w-full"
                />
              )}
            </div>
            <div className="block md:hidden">
              {isDownloadDropdownOpen && (
                <DownloadDropdown
                  actions={downloadMobileActions}
                  onClose={() => setIsDownloadDropdownOpen(false)}
                  position="top-[60px] right-[10px]"
                  searchable={false}
                  width="w-full"
                />
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-between pt-[50px] pb-[32px] w-full z-30 bg-white lg:bg-[#F8FAFF]">
          {/* Search input */}
          <div className="relative lg:block hidden">
            <input
              type="search"
              className="w-[323px] h-[50px] rounded-[10px] border border-[#F1F1F1] py-4 pr-5 pl-10 shadow-sm outline-primary"
              placeholder="Search by Customer ID or Name"
            />
            <div className="absolute top-1/2 left-4 transform -translate-y-1/2 pointer-events-none">
              <img
                src={search}
                alt="search icon"
                className="w-[18px] h-[18px]"
              />
            </div>
          </div>

          {/* Date and Status */}
          <div className="flex items-center gap-4">
            <div className="relative">
              <button
                onClick={() => setIsDateDropdownOpen((prev) => !prev)}
                className="flex items-center gap-1 border-[1px] border-dateBorder w-[122px] h-[45px] rounded-[8px] py-[12px] px-[30px] shadow-sm bg-[#FAFDFF]"
              >
                <img
                  src={calendar}
                  alt="Calendar icon"
                  className="w-[20px] h-[20px]"
                />
                <span className="font-[300] text-[14px] leading-[21px] text-center font-montserrat text-[#1C1C1E]">
                  Date
                </span>
                <IoIosArrowDown className="text-[#1C1C1E] w-[16px] h-[16px]" />
              </button>
          
              {isDateDropdownOpen && (
                <div className="w-[200px]">
                <Dropdown
                  actions={dateActions}
                  onClose={() => setIsDateDropdownOpen(false)}
                  position="top-[60px]"
                  searchable={false}
                  width="w-full"
                />
                </div>
              )}
              {selectedRange === 'custom' && (
  <div className="flex gap-4 mt-2 absolute top-[100%] -right-[200px] md:right-0 z-50">
    <input
      type="date"
      value={startDate || ''}
      onChange={(e) => setStartDate(e.target.value)}
      className="border border-gray-300 rounded-[5px] p-2"
    />
    <input
      type="date"
      value={endDate || ''}
      onChange={(e) => setEndDate(e.target.value)}
      className="border border-gray-300 rounded-[5px] p-2"
    />
  </div>
)}

            </div>

            {/* <div className="relative">
              <button
                onClick={() => setIsStatusDropdownOpen((prev) => !prev)}
                className="flex items-center gap-1 border-[1px] border-dateBorder w-auto h-[45px] rounded-[8px] py-[12px] px-[30px] shadow-sm bg-[#FAFDFF]"
              >
                <span className="font-[400] text-[12px] leading-[18px] text-center text-[#1C1C1C]">
                  Status
                </span>
                {isStatusDropdownOpen ? (
                  <IoIosArrowUp className="text-[#1C1C1E] w-[16px] h-[16px]" />
                ) : (
                  <IoIosArrowDown className="text-[#1C1C1E] w-[16px] h-[16px]" />
                )}
              </button>
              {isStatusDropdownOpen && (
                <Dropdown
                  actions={statusActions}
                  onClose={() => setIsStatusDropdownOpen(false)}
                  position="top-[60px]"
                  searchable={false}
                  width="w-full"
                />
              )}
            </div> */}
            {/* Mobile Search */}
            <div className="md:hidden flex justify-center items-center bg-primary  w-[52px] h-[44px] py-[12px] px-4 rounded-[8px] cursor-pointer">
              <IoSearch className="text-[#FAFDFF] w-full h-full" />
            </div>
          </div>
        </div>

        <div className="h-auto max-h-[609px] w-full bg-white ">
          {loading && (
            <p className="text-center flex justify-center items-center">
              Loading...
            </p>
          )}
          {error && (
            <div className="flex flex-col gap-2 items-center">
            <p className="text-red-500 text-center flex justify-center items-center">
              Error: {error}
            </p>
            <button onClick={() => dispatch(fetchCustomers({ page: currentPage })) } className="bg-primary text-white p-2 rounded-[8px]">Retry</button>
            </div>
          )}
        
          {/* Table */}
          {!loading && !error && (
            <Table
              headers={headers}
              data={filteredCustomers}
              renderRow={renderRow}
              boxClassName="overflow-y-auto h-full overflow-x-auto"
            />
          )}
        </div>
        <Pagination
          prevPage={prevPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          nextPage={nextPage}
          totalPages={totalPages}
        />
      </div>
    </HelmetProvider>
  );
};

export default CustomerList;
