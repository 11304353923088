import React, { useState } from "react";
import { IoIosClose } from "react-icons/io";
import InputField from "./inputField";
import Button from "./button";
import ModalHeader from "./modalHeader";
import carOptions from "../utils/dummyData/carOptions";
import { Camera01Icon } from "hugeicons-react";
import useCameraScanner from "../utils/customhooks/CameraScanner";
import { FiInfo } from "react-icons/fi";
import Webcam from "react-webcam";
import { toast } from "sonner";
import { useDispatch, useSelector } from "react-redux";
import { createCustomer, fetchCustomers } from "../store/customerSlice";
import { FaSpinner } from "react-icons/fa6";

const AddCustomer = ({ closeModal }) => {
  const {
    isCameraOpen,
    toggleCamera,
    webcamRef,
    captureImage,
    isProcessing,
    scannedText,
  } = useCameraScanner();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [carMake, setCarMake] = useState("");
  const [carModel, setCarModel] = useState("");
  const [subActions, setSubActions] = useState([]);
  const [mileage, setMileage] = useState("");
  // const [date, setDate] = useState("");
  const [customBrand, setCustomBrand] = useState(""); // For custom car make
  const [customModel, setCustomModel] = useState(""); // For custom car model
const [isSubmitting, setIsSubmitting] = useState(false);
const { page} = useSelector(
  (state) => state.customer
);
  React.useEffect(() => {
    if (scannedText) {
      setMileage(scannedText);
    }
  }, [scannedText]);

  const handleBrandChange = (selectedValue) => {
    setCarMake(selectedValue);
    setCustomBrand(""); // Reset custom brand input

    if (selectedValue === "others") {
      setSubActions([]); // Clear sub-actions for "Others"
    } else {
      const brand = carOptions.find((option) => option.value === selectedValue);
      setSubActions(brand ? brand.subActions : []);
      setCarModel(""); // Reset model when brand changes
    }
  };
  const handleModelChange = (model) => {
    setCarModel(model);
    setCustomModel(""); // Reset custom model input
  };

  const brandOptions = [
    ...carOptions.map((option) => ({
      label: option.label,
      value: option.value,
    })),
    { label: "Others", value: "Others" }, // Add "Others" option
  ];
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Start loading

    // Call your async action 
    try {
      await dispatch(createCustomer({
        name,
       email, phone, address, carMake, carModel, mileage, customBrand, customModel
      })).unwrap();
      toast.success("Customer created successfully");
      dispatch(fetchCustomers({ page }))
      closeModal();
    } catch (error) {
      console.error("Error submitting customer", error);
      toast.error(error || "Create customer failed, please try again later.")
    } finally {
      setIsSubmitting(false); // Stop loading
    }
  };
  
  return (
    <>
      <div
        className="fixed inset-0 z-40 flex justify-center items-center lg:items-start lg:justify-end bg-black bg-opacity-40 font-dmsans"
        onClick={closeModal}
      >
        <div
          className="bg-white shadow-lg w-full lg:w-[60vw] rounded-l-[10px] h-[90%] lg:h-full overflow-y-auto scrollbar-thin scrollbar-thumb-scrollbar-thumb hover:scrollbar-thumb-scrollbar-thumb-hover  scroll-smooth flex flex-col gap-8"
          onClick={(e) => e.stopPropagation()}
        >
          <ModalHeader
            header=" Add New Customer"
            onClick={closeModal}
            icon={<IoIosClose className="w-full h-full" />}
          />

          <form className="flex flex-col gap-[26px] px-6 lg:px-[60px] w-full pt-[20px] lg:mt-0 bg-white">
            <InputField
              label="Customer Name"
              name="name"
              type="text"
              asteriks={true}
              placeholder="Enter name"
              className="w-full flex flex-col gap-2"
              inputClassName="w-full h-[58px] lg:h-[69px] rounded-[12px] lg:rounded-[13px]"
              value={name}
              handleValue={setName}
            />

            <div className="flex flex-col gap-[12px] lg:flex-row lg:gap-[20px] w-full">
              <InputField
                label="Email"
                name="email"
                type="email"
                value={email}
                handleValue={setEmail}
                asteriks={true}
                placeholder="e.g www.adejoko.com"
                className="w-full flex flex-col gap-2"
                inputClassName="w-full h-[58px] lg:h-[69px] rounded-[12px] lg:rounded-[13px]"
                labelIcon={<FiInfo className="text-[#D9D9D9] w-5 h-5" />}
              />
              <InputField
                label="Phone Number"
                name="phoneNumber"
                type="number"
                value={phone}
                handleValue={setPhone}
                asteriks={true}
                placeholder="e.g 23456789"
                className="w-full flex flex-col gap-2"
                inputClassName="w-full h-[58px] lg:h-[69px] rounded-[12px] lg:rounded-[13px]"
                labelIcon={<FiInfo className="text-[#D9D9D9] w-5 h-5" />}
              />
            </div>
            <div className="flex flex-col lg:flex-row gap-[12px] lg:gap-[20px] w-full">
              <InputField
                label="Address"
                name="address"
                type="text"
                value={address}
                handleValue={setAddress}
                asteriks={true}
                placeholder="e.g Canada"
                className="w-full flex flex-col gap-2"
                inputClassName="w-full h-[58px] lg:h-[69px] rounded-[12px] lg:rounded-[13px]"
                labelIcon={<FiInfo className="text-[#D9D9D9] w-5 h-5" />}
              />
              {/* <InputField
                asteriks={true}
                label="Date"
                type="calendar"
                name="date"
                value={date}
                handleValue={setDate}
                placeholder="Select a date"
                calendarClassName="w-full"
                className="w-full"
              /> */}
            </div>
            <div className="flex flex-col lg:flex-row gap-[12px] lg:gap-[20px] w-full">
              <InputField
                label="Car Make"
                name="carMake"
                type="select"
                asteriks={true}
                options={brandOptions}
                placeholder="e.g Honda Civic (2019)"
                className="w-full flex flex-col gap-2"
                selectClassName="w-full flex justify-center items-center rounded-[12px] md:rounded-[13px] h-[58px] md:h-[69px]"
                labelIcon={<FiInfo className="text-[#D9D9D9] w-5 h-5" />}
                handleValue={handleBrandChange}
                value={carMake}
                searchable={true}
              />
              {carMake === "Others" && (
                <div className="flex flex-col gap-[7px] w-full">
                  <div className="flex gap-2">
                    {" "}
                    <label className="font-[400] text-[15px] leading-[19px] text-black">
                      Custom Car Make
                      <span className="text-[#FF0000]">*</span>
                    </label>
                    <FiInfo className="text-[#D9D9D9] w-5 h-5" />
                  </div>

                  <div className="relative w-full  flex items-center">
                    <input
                      type="text"
                      value={customBrand}
                      onChange={(e) => setCustomBrand(e.target.value)}
                      placeholder="Enter Car Make"
                      className="w-full h-[58px] lg:h-[69px] rounded-[12px] lg:rounded-[13px] bg-white py-4 px-[14px] border border-[#E7E7E7] outline-[#00325E]"
                    />
                  </div>
                </div>
              )}

              {subActions.length > 0 || carMake === "others" ? (
                <InputField
                  label="Car Model"
                  name="carModel"
                  type="select"
                  asteriks={true}
                  options={[
                    ...subActions.map((action) => ({
                      label: action.label,
                      value: action.label,
                    })),
                    { label: "Others", value: "others" }, // Add "Others" option
                  ]}
                  placeholder="Select a car model"
                  className="w-full flex flex-col gap-2"
                  selectClassName="w-full flex justify-center items-center rounded-[12px] md:rounded-[13px] h-[58px] md:h-[69px]"
                  handleValue={handleModelChange}
                  value={carModel}
                  searchable={true}
                />
              ) : null}

              {carModel === "others" && (
                <div className="flex flex-col gap-[7px] w-full">
                  <div className="flex gap-2">
                    {" "}
                    <label className="font-[400] text-[15px] leading-[19px] text-black">
                      Custom Car Model
                      <span className="text-[#FF0000]">*</span>
                    </label>
                    <FiInfo className="text-[#D9D9D9] w-5 h-5" />
                  </div>

                  <div className="relative w-full  flex items-center">
                    <input
                      type="text"
                      value={customModel}
                      onChange={(e) => setCustomModel(e.target.value)}
                      placeholder="Enter Car Model"
                      className="w-full h-[58px] lg:h-[69px] rounded-[12px] lg:rounded-[13px] bg-white py-4 px-[14px] border border-[#E7E7E7] outline-[#00325E]"
                    />
                  </div>
                </div>
              )}
              <div className="flex flex-col gap-[7px] w-full">
                <div className="flex gap-2">
                  {" "}
                  <label className="font-[400] text-[15px] leading-[19px] text-black">
                    Mileage
                    <span className="text-[#FF0000]">*</span>
                  </label>
                  <FiInfo className="text-[#D9D9D9] w-5 h-5" />
                </div>

                <div className="relative w-full  flex items-center">
                  <input
                    type="text"
                    value={mileage}
                    onChange={(e) => setMileage(e.target.value)}
                    placeholder="e.g ABC-1234"
                    className="w-full h-[58px] lg:h-[69px] rounded-[12px] lg:rounded-[13px] bg-white py-4 px-[14px] border border-[#E7E7E7] outline-[#00325E]"
                  />
                  <span
                    className="absolute right-3 cursor-pointer"
                    onClick={toggleCamera}
                  >
                    <Camera01Icon className="w-[24px] h-[24px]" />
                  </span>
                </div>
              </div>
            </div>
            <div className="flex  items-center justify-center lg:justify-around my-8 lg:mt-[60px] lg:mb-[100px]">
              <Button
                type="secondary"
                borderRadius="rounded-[5px]"
                size="S"
                label="Cancel"
                className="w-[119px]  hidden lg:flex justify-center items-center"
                onClick={closeModal}
              />
              <Button
                type="tertiary"
                borderRadius="rounded-[5px]"
                size="S"
               label={isSubmitting ? ( <FaSpinner className="animate-spin mx-auto" />) : "Submit"}
                className="lg:w-[186px] w-full"
                onClick={handleSubmit}
              />
            </div>
          </form>
        </div>
      </div>

      {isCameraOpen && (
        <div className="fixed inset-0 z-40 flex justify-center items-center  bg-black bg-opacity-40 font-dmsans">
          <div className="bg-white rounded-[8px] w-full p-3 lg:w-[50vw] max-h-[90vh]">
            <div className="flex flex-col items-center gap-4">
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                className="w-full h-[300px] rounded-[13px] border border-[#E7E7E7]"
              />
              <Button
                type="primary"
                borderRadius="rounded-[8px]"
                size="M"
                label="Capture Photo"
                onClick={captureImage}
              />
              <Button
                type="secondary"
                borderRadius="rounded-[8px]"
                size="M"
                label="Cancel"
                onClick={toggleCamera}
              />
            </div>
          </div>
        </div>
      )}

      {isProcessing && (
        <div className="fixed inset-0 z-40 flex justify-center items-center  bg-black bg-opacity-40 font-dmsans">
          <p className="text-gray-500">Processing image...</p>
        </div>
      )}
    </>
  );
};

export default AddCustomer;
