import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const baseUrl = process.env.REACT_APP_API_URL;

const initialState = {
  customers: [],
  loading: false,
  error: null,
  page: 1,
    totalPages: 1,
    totalCustomers: 0,
};

export const createCustomer = createAsyncThunk(
  "auth/createCustomer",
  async (
    { name, email, address, phone, date, carMake, mileage, carModel },
    thunkAPI
  ) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.token.data.token;
      const response = await fetch(`${baseUrl}/v1/customer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name,
          email,
          address,
          phone,
          date,
          carMake,
          mileage,
          carModel,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to create customer");
      }

      return data; // Assuming API returns success message
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const fetchCustomers = createAsyncThunk(
  "customers/fetchCustomers",
  async ({page = 1}, { getState }) => {
    // Retrieve token
    const token = getState().auth.token.data.token; // If using Redux store

    const response = await fetch(`${baseUrl}/v1/customer?page=${page}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch customers");
    }

    const data = await response.json();
    console.log("Data", data);
    return {
      customers: data.data.customers,
      page: data.data.page,
      totalPages: data.data.pages,
      totalCustomers: data.data.total,
    };
  }
);

export const fetchCustomerById = createAsyncThunk(
  "customers/fetchCustomerById", async (customerId, { getState }) => {
  try {
    const token = getState().auth.token.data.token; // If using Redux store
    const response = await fetch(`${baseUrl}/v1/customer/${customerId}`, {
    
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`, // Include token if required
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    if (response.ok) {
      return data.data; // Return customer data
    } else {
      console.error("Error fetching customer:", data.message);
    }
  } catch (error) {
    console.error("Network error:", error);
  }
});

export const editCustomer = createAsyncThunk(
  "customers/editCustomer",
  async ({id, name, email, address, phone, carMake, mileage, carModel}, { getState, rejectWithValue }) => {
    try {
    // Retrieve token
    const token = getState().auth.token.data.token; // If using Redux store

    const response = await fetch(`${baseUrl}/v1/customer/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ name, email, address, phone, carMake, mileage, carModel }),
    });

    if (!response.ok) {
      throw new Error("Failed to edit customer");
    }

    const data = await response.json();
    console.log("Data", data);
     return data.data;
  }
  catch (error) {
    return rejectWithValue(error.message);
  }
}
);
export const deleteCustomer = createAsyncThunk(
  "customers/deleteCustomer",
  async ({id}, { getState, rejectWithValue }) => {
    try {
    // Retrieve token
    const token = getState().auth.token.data.token; // If using Redux store

    const response = await fetch(`${baseUrl}/v1/customer/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ id }),
    });

    if (!response.ok) {
      throw new Error("Failed to delete customer");
    }

    const data = await response.json();
    console.log("Data", data);
     return data;
  }
  catch (error) {
    return rejectWithValue(error.message);
  }
}
);


const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCustomer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload.message;
      })
      .addCase(createCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchCustomers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.customers = action.payload.customers;
        state.page = action.payload.page;
        state.totalPages = action.payload.totalPages;
        state.totalCustomers = action.payload.totalCustomers;
        state.loading = false;
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(editCustomer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editCustomer.fulfilled, (state, action) => {
        state.loading = false;
        const updatedCustomer = action.payload;
        
        // Update the customer in the state
        state.customers = state.customers.map((customer) =>
          customer.id === updatedCustomer.id ? updatedCustomer : customer
        );
      })
      .addCase(editCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchCustomerById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomerById.fulfilled, (state, action) => {
        state.loading = false;
        // Add fetched customer data
        state.customers = [action.payload]; // Assuming you only have one customer
      })
      .addCase(fetchCustomerById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(deleteCustomer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload.message;
      })
      .addCase(deleteCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default customerSlice.reducer;
