import React from 'react';

const Button = ({
	type = 'primary',
	label,
	onClick,
	iconLeft,
	iconRight,
	disabled = false,
	loading = false,
	className = '',
	trackLabel,
	size = 'M',
	borderRadius = 'rounded-md',
    iconLeftClassName,
    iconRightClassName
}) => {
	const baseStyles =
		'flex items-center justify-center font-montserrat  transition-all duration-300 ease-in-out focus:outline-none  cursor-pointer';

	const sizeStyles = {
		S: 'h-[48px] md:h-[60px] px-4 text-[14px] md:text-[16px]',
		XS: 'h-[50px] px-6 text-[12px] leading-[20px]',
        XXS: 'h-[34px] px-6 py-2 text-[12px] leading-[18px]',
		M: 'h-[52px] px-6 text-[14px] leading-[24px]',
		L: 'h-[60px] px-8 text-lg',
	};

	const primaryStyles = `bg-primary min-w-[120px] text-[#EEF2F6] disabled:text-gray-500 hover:bg-[#00325E] disabled:bg-gray-100 ${borderRadius}`;

	const secondaryStyles = `bg-[#F8FCFF]  font-[600] leading-[20px] border border-[#DDE4F0] text-[#00325E] hover:text-primary hover:border-primary disabled:text-gray-500 disabled:bg-gray-100 ${borderRadius}`;

	const tertiaryStyles = `bg-[#00325E] min-w-[120px] text-white disabled:text-gray-500 ${borderRadius} `;

	

	const buttonStyles =
		type === 'primary'
			? primaryStyles
			: type === 'secondary'
			? secondaryStyles
			: tertiaryStyles;

	const finalButtonStyles = `${baseStyles} ${
		sizeStyles[size] || sizeStyles.M
	} ${buttonStyles}`;

	const handleClick = (event) => {
		if (disabled || loading) return;

		if (window.gtag) {
			window.gtag('event', 'button_click', {
				event_category: 'Button',
				event_label: trackLabel || label,
				value: 1,
			});
		}

		if (onClick) {
			onClick(event);
		}
	};

	return (
		<button
		type='button'
			onClick={handleClick}
			disabled={disabled || loading}
			className={`${finalButtonStyles} ${className}`}>
			{loading ? (
				<div className='spinner w-5 h-5 border-2 border-t-transparent border-white rounded-full animate-spin'></div>
			) : (
				<div className='flex items-center'>
					{iconLeft && <span className={`mr-2 ${iconLeftClassName}`}>{iconLeft}</span>}
					{label}
					{iconRight && <span className={`'ml-2' ${iconRightClassName}  `}>{iconRight}</span>}
				</div>
			)}
		</button>
	);
};

export default Button;
