import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Avatar from "./avatar";
import { IoIosArrowForward } from "react-icons/io";
// import { MdOutlineLightMode } from "react-icons/md";
// import { Moon02Icon } from "hugeicons-react";
import { IoSettingsOutline } from "react-icons/io5";
import { Notification01Icon } from "hugeicons-react";


const Header = ({ subHeading, headerTitle, subTab, toggleDropdown, tabs, }) => {
    const merchant = useSelector((state) => state.auth.merchant);
  const firstName = merchant?.firstName;
  const lastName = merchant?.lastName;
 
  // Toggle function
  // const toggleTheme = () => setIsDarkMode((prev) => !prev);

// Helper function to find the path for the subHeading or headerTitle
const findPathByTitle = (title) => {
  const tab = tabs.find(
    (t) =>
      t.headerTitle === title || t.subTabs?.some((subTab) => subTab.name === title)
  );

  if (tab) {
    return (
      tab.path || 
      tab.subTabs?.find((subTab) => subTab.name === title)?.path
    );
  }
  return "#"; // Fallback if no path is found
};


  return (
    <div className="hidden  fixed top-0 left-[319px] h-[90px] py-[50px] lg:w-[calc(100%-319px)] lg:px-[48px] bg-[#F8FAFF] z-30 lg:flex items-center">
      <div className="flex justify-between items-center w-full">
        <div className="w-full">
          {subHeading && headerTitle && (
            <div className="flex items-center">
              <h1 className="font-[400] text-[16px] leading-[13px] text-lightGreyBg -tracking-[0.5px] flex items-center">
                {subHeading}
                <span className="px-[15px]">
                  <IoIosArrowForward />
                </span>
              </h1>
              <Link to={findPathByTitle(headerTitle)}> <h1 className="text-[#1C1C1C] font-[400] text-[16px] leading-[13px]">
                {headerTitle}
              </h1>
              </Link> 
              <h1 className="text-[#1C1C1C] font-[400] text-[16px] leading-[13px] flex items-center">
                <span className="px-[15px]">
                  {subTab ? <IoIosArrowForward /> : ""}
                </span>
                <Link to={findPathByTitle(subTab)}> {subTab}</Link>
              </h1>
            </div>
          )}
        </div>
        <div className="flex items-center gap-12">
          {/* Toggle Dark mode and light mode */}
          {/* <div
            className={`bg-toggleBg flex items-center w-auto gap-[9px] h-[40px]  rounded-[30px] cursor-pointer`}
            onClick={toggleTheme}
          >
            <div
              className={`h-[40px] w-[38px] rounded-full flex justify-center items-center transition-colors duration-500  ${
                !isDarkMode ? "bg-gray-800" : ""
              }`}
            >
              <MdOutlineLightMode
                className={`w-[24px] h-[24px] transition-colors duration-500 ${
                  !isDarkMode ? "text-white" : "text-primary"
                }`}
              />
            </div>
            <div
              className={`"h-[40px] w-[38px] rounded-full flex justify-center items-center transition-colors duration-500 ${
                !isDarkMode ? "" : "bg-gray-800"
              } "`}
            >
              <Moon02Icon
                className={`w-[32px] h-[32px] transition-colors duration-500 ${
                  !isDarkMode ? "text-primary" : " text-white"
                }`}
              />
            </div>
          </div> */}

          {/* Settings */}
          <Link to={"/settings"}>
          <IoSettingsOutline className="w-[28px] h-[28px] text-[#48494D] "/>
            
          </Link>
          {/* Notifications */}
          <Link to={"/notifications"}>
          <Notification01Icon className="w-[28px] h-[28px] text-[#48494D] "/>
          </Link>
          {/* Avatar */}
          <div className="cursor-pointer" onClick={toggleDropdown}>
            <Avatar firstName={firstName} lastName={lastName} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
