import React, { useState, useRef, useEffect } from "react";
import calendar from "../assets/images/solarcalendar.svg";
import search from "../assets/images/search.svg";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FaEllipsisVertical } from "react-icons/fa6";
// import inventory from "../utils/dummyData/inventoryData";
import Table from "../components/Table";
import { IoSearch } from "react-icons/io5";
import Pagination from "../components/pagination";
import Button from "../components/button";
import { BsDownload } from "react-icons/bs";
import Dropdown from "../components/dropdown";
import DownloadDropdown from "../components/downloadDropdown";
import useClickOutside from "../utils/customhooks/useClickOutside";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { fetchInventory } from "../store/inventorySlice";

const Inventory = ({ openAddInventoryModal, openEditInventoryModal }) => {
  const dispatch = useDispatch();
  const pageSize = 10;
  // Access inventory from Redux state
  const { inventory, loading, error, totalPages } = useSelector(
    (state) => state.inventory
  );

  const [currentPage, setCurrentPage] = useState(1);
  // const sortedInventory = [...inventory].sort(
  //   (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  // );
  useEffect(() => {
    dispatch(fetchInventory({ page: currentPage }));
  }, [dispatch, currentPage]);
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const dropDownRef = useRef(null);
  const handleDropDown = (customerId) => {
    if (openDropdownId === customerId) {
      setOpenDropdownId(null); // Close dropdown if it's already open
    } else {
      setOpenDropdownId(customerId); // Open dropdown for specific customer
    }
  };
  const handleEditInventoryModal = (inventory) => {
    setOpenDropdownId(null);
    openEditInventoryModal(inventory);
  };
  // State for dropdown visibility
  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [isDownloadDropdownOpen, setIsDownloadDropdownOpen] = useState(false);

  const dateDropdownRef = useRef(null);
  const dateButtonRef = useRef(null);

  const downloadDropdownRef = useRef(null);
  const downloadButtonRef = useRef(null);

  const statusDropdownRef = useRef(null);
  const statusButtonRef = useRef(null);
  // Handle click outside for Date dropdown
  useClickOutside([dateDropdownRef, dateButtonRef], () =>
    setIsDateDropdownOpen(false)
  );

  // Handle click outside for Status dropdown
  useClickOutside([statusDropdownRef, statusButtonRef], () =>
    setIsStatusDropdownOpen(false)
  );

  // Handle click outside for Download dropdown
  useClickOutside([downloadDropdownRef, downloadButtonRef], () =>
    setIsDownloadDropdownOpen(false)
  );

  // Dropdown actions
  const dateActions = [
    { label: "Today", onClick: () => console.log("Filter: Today") },
    { label: "This Week", onClick: () => console.log("Filter: This Week") },
    { label: "This Month", onClick: () => console.log("Filter: This Month") },
  ];

  const statusActions = [
    { label: "Completed", onClick: () => console.log("Filter: Completed") },
    { label: "In-progress", onClick: () => console.log("Filter: In-progress") },
    { label: "Pending", onClick: () => console.log("Filter: Pending") },
  ];
  const downloadActions = [
    { label: "Download as PDF", onClick: () => console.log("Download as PDF") },
    {
      label: "Download as Plain Text",
      onClick: () => console.log("Download as Plain Text"),
    },
    {
      label: "Download as Microsoft word",
      onClick: () => console.log("Download as Microsoft word"),
    },
  ];
  const downloadMobileActions = [
    { label: "PDF", onClick: () => console.log("Download as PDF") },
    {
      label: "Plain Text",
      onClick: () => console.log("Download as Plain Text"),
    },
    {
      label: "Microsoft word",
      onClick: () => console.log("Download as Microsoft word"),
    },
  ];

  const headers = [
    "S/N",
    "Inventory ID",
    "Item Name ",
    "Category",
    "Qty on Order",
    "Qty in Stock",
    "Supplier",
    "Action",
  ];
  const renderRow = (item, index, rowClass) => (
    <tr key={item.id} className={rowClass}>
      <td
        className={`border-b font-[400] text-[14px] leading-[21px] text-strokeColor px-6 py-4 my-2 text-left text-nowrap sticky left-0 z-20`}
        style={{ backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F9FAFB" }}
      >
          {index + 1 + (currentPage - 1) * pageSize} 
      </td>
      <td className="border-b font-[400] text-[14px] text-center leading-[21px] text-nowrap text-strokeColor  px-4 py-4 my-2">
        {item.id}
      </td>
      <td className="py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-nowrap text-strokeColor  ">
        {item.name}
      </td>
      <td className="py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-nowrap text-strokeColor  ">
        {item.category.name}
      </td>
      <td className="py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-nowrap text-strokeColor  ">
        {item.quantityOnOrder}
      </td>
      <td className="py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-nowrap text-strokeColor  ">
        {item.quantityInStock}
      </td>
      <td className="py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-nowrap text-strokeColor  ">
        {item.supplierName}
      </td>

      <td className="py-4 text-center border-b relative">
        <div
          onClick={() => handleDropDown(item.id)}
          className="flex justify-center cursor-pointer "
        >
          <FaEllipsisVertical className="text-primary" />
        </div>
        {openDropdownId === item.id && (
          <div
            ref={dropDownRef}
            className="absolute right-[10px] top-[60px] w-[117px] h-auto rounded-[8px] p-4 bg-[#FAFAFA] shadow-sm border z-20 border-[#D9D9D9] flex flex-col items-start justify-center gap-4"
          >
            <button
              onClick={() => handleEditInventoryModal(item)}
              className="font-[400] text-[12px] leading-[20px] text-[#273240]"
            >
              Edit
            </button>
            <button className="font-[400] text-[12px] leading-[20px] text-[#273240] ">
              Reorder
            </button>
          </div>
        )}
      </td>
    </tr>
  );
  return (
    <HelmetProvider>
      <Helmet>
        <title>Hamzaa Merchant | Inventory</title>
        <meta name="description" content="Hamzaa Inventory page" />
      </Helmet>
      <div className=" lg:absolute lg:top-[90px] w-full lg:w-[calc(100%-319px)] pt-4 px-6 z-10 lg:px-[48px] pb-[40px] min-h-[100vh] font-dmsans bg-white lg:bg-[#F8FAFF]">
        <div className="mt-[100px] lg:mt-[50px] flex gap-5 lg:gap-0 lg:justify-between items-center">
          <Button
            onClick={openAddInventoryModal}
            type="tertiary"
            borderRadius="rounded-[5px]"
            size="XS"
            label="Add inventory"
            className="w-full lg:w-auto font-[500]"
            iconLeft="+"
            iconLeftClassName="text-[22px] leading-[21px]"
          />
          <div className="relative">
            <button
              ref={downloadButtonRef}
              onClick={() => setIsDownloadDropdownOpen((prev) => !prev)}
              className="flex justify-between lg:justify-center items-center gap-1 border-[1px] border-[#D9D9D9] w-full lg:w-auto h-[50px] rounded-[5px] py-[4px] px-[8px] shadow-sm bg-[#FAFDFF]"
            >
              <div className="block lg:hidden">
                <BsDownload className="text-[#1C1C1C] w-4 h-4" />
              </div>
              <div className="flex items-center gap-8 lg:gap-1">
                <span className="text-center text-[#1C1C1C]">
                  <span className="md:block hidden font-dmsans font-[400] text-[12px] leading-[18px]">
                    {" "}
                    Download{" "}
                  </span>
                  {/* <span className="block lg:hidden font-montserrat font-[400] text-[14px] leading-[21px] ">PDF</span> */}
                </span>
                <button className="flex justify-center items-center">
                  {isDownloadDropdownOpen ? (
                    <IoIosArrowUp className="text-[#1C1C1E] w-[16px] h-[16px]" />
                  ) : (
                    <IoIosArrowDown className="text-[#1C1C1E] w-[16px] h-[16px]" />
                  )}
                </button>
              </div>
            </button>
            <div className="hidden md:block">
              {isDownloadDropdownOpen && (
                <div ref={downloadDropdownRef}>
                  <DownloadDropdown
                    actions={downloadActions}
                    onClose={() => setIsDownloadDropdownOpen(false)}
                    position="top-[60px] right-[10px]"
                    searchable={false}
                    width="w-full"
                  />
                </div>
              )}
            </div>
            <div className="block md:hidden">
              {isDownloadDropdownOpen && (
                <div ref={downloadDropdownRef}>
                  <DownloadDropdown
                    actions={downloadMobileActions}
                    onClose={() => setIsDownloadDropdownOpen(false)}
                    position="top-[60px] right-[10px]"
                    searchable={false}
                    width="w-full"
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-between  pt-[50px] pb-[32px] w-full z-30 bg-white lg:bg-[#F8FAFF]">
          {/* Search input */}
          <div className="relative lg:block hidden">
            <input
              type="search"
              className="w-[323px] h-[50px] rounded-[10px] border border-[#F1F1F1] py-4 pr-5 pl-10 shadow-sm outline-primary"
              placeholder="Search by Name or Service"
            />
            <div className="absolute top-1/2 left-4 transform -translate-y-1/2 pointer-events-none">
              <img
                src={search}
                alt="search icon"
                className="w-[18px] h-[18px]"
              />
            </div>
          </div>
          {/* Date and Status */}
          <div className="flex items-center gap-4">
            <div className="relative">
              <button
                ref={dateButtonRef}
                onClick={() => setIsDateDropdownOpen((prev) => !prev)}
                className="flex items-center gap-1 border-[1px] border-dateBorder w-[122px] h-[45px] rounded-[8px] py-[12px] px-[30px] shadow-sm bg-[#FAFDFF]"
              >
                <img
                  src={calendar}
                  alt="Calendar icon"
                  className="w-[20px] h-[20px]"
                />
                <span className="font-[300] text-[14px] leading-[21px] text-center font-montserrat text-[#1C1C1E]">
                  Date
                </span>
                <IoIosArrowDown className="text-[#1C1C1E] w-[16px] h-[16px]" />
              </button>
              {isDateDropdownOpen && (
                <div ref={dateDropdownRef}>
                  <Dropdown
                    actions={dateActions}
                    onClose={() => setIsDateDropdownOpen(false)}
                    position="top-[60px]"
                    searchable={false}
                    width="w-full"
                  />
                </div>
              )}
            </div>

            <div className="relative">
              <button
                ref={statusButtonRef}
                onClick={() => setIsStatusDropdownOpen((prev) => !prev)}
                className="flex items-center gap-1 border-[1px] border-dateBorder w-auto h-[45px] rounded-[8px] py-[12px] px-[30px] shadow-sm bg-[#FAFDFF]"
              >
                <span className="font-[400] text-[12px] leading-[18px] text-center text-[#1C1C1C]">
                  Status
                </span>
                {isStatusDropdownOpen ? (
                  <IoIosArrowUp className="text-[#1C1C1E] w-[16px] h-[16px]" />
                ) : (
                  <IoIosArrowDown className="text-[#1C1C1E] w-[16px] h-[16px]" />
                )}
              </button>
              {isStatusDropdownOpen && (
                <div ref={statusDropdownRef}>
                  <Dropdown
                    actions={statusActions}
                    onClose={() => setIsStatusDropdownOpen(false)}
                    position="top-[60px]"
                    searchable={false}
                    width="w-full"
                  />
                </div>
              )}
            </div>
            {/* Mobile Search */}
            <div className="md:hidden flex justify-center items-center bg-primary  w-[52px] h-[44px] py-[12px] px-4 rounded-[8px] cursor-pointer">
              <IoSearch className="text-[#FAFDFF] w-full h-full" />
            </div>
          </div>
        </div>
        <div className="overflow-hidden h-[609px] w-full ">
         {loading && (
                    <p className="text-center flex justify-center items-center">
                      Loading...
                    </p>
                  )}
                  {error && (
                    <div className="flex flex-col gap-2 items-center">
                    <p className="text-red-500 text-center flex justify-center items-center">
                      Error: {error}
                    </p>
                    <button onClick={() => dispatch(fetchInventory({ page: currentPage })) } className="bg-primary text-white p-2 rounded-[8px]">Retry</button>
                    </div>
                  )}
                  {!loading && !error && (  <Table
            headers={headers}
            data={inventory}
            renderRow={renderRow}
            boxClassName="overflow-y-auto h-full"
          />)}
        </div>

        <Pagination   prevPage={prevPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          nextPage={nextPage}
          totalPages={totalPages} />
      </div>
    </HelmetProvider>
  );
};

export default Inventory;
