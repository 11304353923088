import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


const baseUrl = process.env.REACT_APP_API_URL;

const initialState = {
    inventory: [],
    categories: [], 
    loading: false,
    error: null,
    page: 1,
    totalPages: 1,
    totalInventory: 0,
};

export const createInventory = createAsyncThunk(
  "auth/createInventory",
  async ({name, quantityInStock, quantityOnOrder, supplierName, category}, thunkAPI) => {
    try {
        const state = thunkAPI.getState();
        const token = state.auth.token.data.token; 
      const response = await fetch(`${baseUrl}/v1/inventory`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, 
        },
        body: JSON.stringify({name,  category, quantityInStock, quantityOnOrder, supplierName }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to send reset password email");
      }

      return data.data; // Assuming API returns success message
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchCategories = createAsyncThunk(
  "auth/fetchCategories",
  async (_, thunkAPI) => {
    try {
        const state = thunkAPI.getState();
        const token = state.auth.token.data.token; 
       
        const response = await fetch(`${baseUrl}/v1/inventory/categories`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Include the token
            },
          });
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Error fetching categories");
      }

      return data.data; // You can return more data if needed
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message || "An error occurred.");
    }
  }
);
export const fetchInventory = createAsyncThunk(
  "inventory/fetchInventory",
  async ({page = 1}, { getState }) => {
    // Retrieve token
    const token = getState().auth.token.data.token; // If using Redux store

    const response = await fetch(`${baseUrl}/v1/inventory?page=${page}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch inventory");
    }

    const data = await response.json();
    console.log("Data", data);
    return {
      inventory: data.data.inventory,
      page: data.data.page,
      totalPages: data.data.pages,
      totalInventory: data.data.total,
    };
  }
);

export const editInventory = createAsyncThunk(
  "customers/editInventory",
  async ({name, id, quantityInStock, quantityOnOrder, supplierName, category}, { getState, rejectWithValue }) => {
    try {
    // Retrieve token
    const token = getState().auth.token.data.token; // If using Redux store

    const response = await fetch(`${baseUrl}/v1/inventory/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ name, quantityInStock, quantityOnOrder, supplierName, category }),
    });

    if (!response.ok) {
      throw new Error("Failed to edit inventory");
    }

    const data = await response.json();
    console.log("Data", data);
     return data.data;
  }
  catch (error) {
    return rejectWithValue(error.message);
  }
}
);
const inventorySlice = createSlice({
    name: "inventory",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(createInventory.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(createInventory.fulfilled, (state, action) => {
            state.loading = false;
            state.message = action.payload.message;
        })
        .addCase(createInventory.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(fetchCategories.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(fetchCategories.fulfilled, (state, action) => {
            state.loading = false;
            state.categories = action.payload;
        })
        .addCase(fetchCategories.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
         .addCase(fetchInventory.pending, (state) => {
                state.loading = true;
                state.error = null;
              })
              .addCase(fetchInventory.fulfilled, (state, action) => {
                state.inventory = action.payload.inventory;
                state.page = action.payload.page;
                state.totalPages = action.payload.totalPages;
                state.totalInventory = action.payload.totalInventory;
                state.loading = false;
              })
              .addCase(fetchInventory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
              })
               .addCase(editInventory.pending, (state) => {
                      state.loading = true;
                      state.error = null;
                    })
                    .addCase(editInventory.fulfilled, (state, action) => {
                      state.loading = false;
                      state.inventory = action.payload;
                      // const updatedInventory = action.payload;
                      
                      // Update the customer in the state
                      // state.inventory = state.inventory.map((invent) =>
                      //   invent.id === updatedInventory.id ? updatedInventory : invent
                      // );
                    })
                    .addCase(editInventory.rejected, (state, action) => {
                      state.loading = false;
                      state.error = action.payload;
                    });
    }
})

export default inventorySlice.reducer;