import React from 'react'
import SideNavModal from './components/SideNavModal'
import SideNav from './components/sideNav'
import MobileHeader from './components/MobileHeader'
import Header from './components/header'
import { Outlet } from 'react-router-dom'

const Layout = ({navbarModalOpen,activeTab,closeModal,  headerTitle,subHeading, subTab, toggleUserDropdown, tabs, setIsBlackMode, isBlackMode, openModal}) => {
  return (
    <div>
         {navbarModalOpen && (
                    <SideNavModal
                      handleTabChange={() => {}}
                      activeTab={activeTab}
                      closeModal={() => closeModal("navbarModalOpen")}
                      openReferralModal={() => openModal("referralModalOpen")}
                    />
                  )}
                  <SideNav
                    openReferralModal={() => openModal("referralModalOpen")}
                    handleTabChange={() => {}}
                    activeTab={activeTab}
                  />
                  <div className="ml-0 lg:ml-[319px]">
                    <MobileHeader
                      openNavbarModal={() => openModal("navbarModalOpen")}
                    />
                    <Header
                      headerTitle={headerTitle}
                      subHeading={subHeading}
                      subTab={subTab}
                      toggleDropdown={toggleUserDropdown}
                      tabs={tabs}
                      setIsDarkMode={setIsBlackMode}
                      isDarkMode={isBlackMode}
                    />
               <Outlet/>
    </div>
    </div>
  )
}

export default Layout