import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "sonner";
import { logout } from "./store/authSlice";
import { closeAllModals } from "./store/modalSlice";

const IdleLogout = ({ timeout = 300000 }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let idleTimer;
    
    const handleLogout = () => {
      dispatch(logout()); // Dispatch logout action
      dispatch(closeAllModals());
      toast.warning("You have been logged out due to inactivity.");
      navigate("/signin"); // Redirect to sign-in page
    };

    const resetTimer = () => {
      // Clear any existing timeout and set a new one
      clearTimeout(idleTimer);
      idleTimer = setTimeout(handleLogout, timeout); // Set the logout timeout
    };

    // Attach event listeners for user activity
    const events = ["mousemove", "keydown", "click"];
    events.forEach((event) =>
      window.addEventListener(event, resetTimer) // Reset timer on activity
    );

    // Initialize the timer on mount
    resetTimer();

    return () => {
      // Cleanup event listeners and timer on unmount
      clearTimeout(idleTimer);
      events.forEach((event) =>
        window.removeEventListener(event, resetTimer)
      );
    };
  }, [dispatch, navigate, timeout]);

  return null; // No UI needed
};

export default IdleLogout;
