import React from 'react'

const Pagination = ({
  prevPage, currentPage, nextPage, totalPages, setCurrentPage
}) => {
  const generatePageNumbers = () => {
    let pages = [];
    const maxPagesToShow = 5;

    if (totalPages <= maxPagesToShow) {
      // Show all pages if total pages <= 5
      pages = [...Array(totalPages).keys()].map((p) => p + 1);
    } else {
      if (currentPage <= 3) {
        // Show first 5 pages
        pages = [1, 2, 3, 4, 5, "...", totalPages];
      } else if (currentPage >= totalPages - 2) {
        // Show last 5 pages
        pages = [1, "...", totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
      } else {
        // Show current page in middle with ellipses
        pages = [1, "...", currentPage - 1, currentPage, currentPage + 1, "...", totalPages];
      }
    }
    return pages;
  };
  return (
    <div className="py-[40px] flex justify-center items-center gap-[9px]">
        <button onClick={prevPage} disabled={currentPage === 1} className="font-inter font-[800] text-[12px] lg:text-[8px] leading-[14px] lg:leading-[10px] text-center">Prev</button>
        {/* <button className="w-[27px] h-[27px] bg-[#00325E] rounded-[3px] flex justify-center items-center text-white font-[800] text-[12px] lg:text-[8px] leading-[14px] lg:leading-[9px] text-center font-inter">{currentPage}</button> */}
        {generatePageNumbers().map((page, index) => (
        <button
          key={index}
          onClick={() => typeof page === "number" && setCurrentPage(page)}
          className={`w-[27px] h-[27px] ${
            currentPage === page ? "bg-[#00325E] text-white" : "bg-gray-200 text-black"
          } rounded-[3px] flex justify-center items-center font-[800] text-[12px] lg:text-[8px] leading-[14px] lg:leading-[9px] text-center font-inter`}
          disabled={page === "..."}
        >
          {page}
        </button>
      ))}
        <button onClick={nextPage} disabled={currentPage === totalPages} className="font-inter font-[800] text-[12px] lg:text-[8px] leading-[14px] lg:leading-[10px] text-center">Next</button>
      </div>
  )
}

export default Pagination