import React, {useState} from 'react'
import Button from "./button";
import InputField from "./inputField";
import { PiPrinterLight } from "react-icons/pi";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { serviceOptions } from '../utils/dummyData/serviceOptions';

const CreateCustomerInvoice = () => {
    const [dateValue, setDateValue] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [customer, setCustomer] = useState("");
const [selectedOilType, setSelectedOilType] = useState("");

  const oilTypeOptions = ["Synthetic Oil", "Conventional Oil", "High-Mileage Oil", "Synthetic Blend Oil", "Diesel Engine Oil", "Turbocharged Engine Oil"];
  const paymentOptions = [
    { label: "Credit Card", value: "credit_card" },
    { label: "PayPal", value: "paypal" },
    { label: "Bank Transfer", value: "bank_transfer" },
  ];

  const customerList = [
{label: 'Jane Josh', value: 'Jane Josh'},
{label: 'Joan Josh', value: 'Joan Josh'},
{label: 'Jannice Josh', value: 'Jannice Josh'},
{label: 'Jean Josh', value: 'Jean Josh'},
{label: 'Justin Josh', value: 'Justin Josh'},
  ];
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleServiceValueChange = (newValue) => {
    const selected = serviceOptions.find((option) => option === newValue);
    setSelectedOptions((prevSelected) => [...prevSelected, selected]);
  };

  const handleDeleteSelected = (valueToRemove) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.filter((option) => option !== valueToRemove)
    );
  };
  const handleDateClick = () => {
    console.log("Date Clicked");
  };
  return (
    <div className='absolute top-[90px] pt-[60px] w-full lg:w-[calc(100%-319px)] z-10 px-[48px] pb-[40px] min-h-[100vh] font-dmsans  bg-[#F8FAFF]'>
    <div className="flex gap-4 items-center  my-8">
              <Button
                type="secondary"
                borderRadius="rounded-[5px]"
                size="XS"
                label="Send to Email"
                className="w-auto bg-[#FBFCFE]"
                iconLeft={<HiOutlineEnvelope  className='w-6 h-6 text-primary'/>}
              />
              <Button
                type="tertiary"
                borderRadius="rounded-[8px]"
                size="XS"
                label="Print Invoice"
                className="w-auto "
                iconLeft={<PiPrinterLight className='w-6 h-6 text-white' />}
              />
            </div>
    <form className="flex flex-col gap-[26px] lg:pr-[250px]  w-full pt-[20px] lg:mt-0 bg-[#F8FAFF]">
          <div className="flex flex-col gap-[26px]">
            <h2 className="font-[500] text-[16px] leading-[30px] text-[#A3A3A3]">
              Customer Details
            </h2>
            <InputField
              label="Customer Name"
              name="name"
              type="select"
                asteriks={true}
                options={customerList}
                placeholder="Select name"
                value={customer}
                handleValue={setCustomer}
                searchable={true}
                className="w-full flex flex-col gap-2"
                selectClassName="w-full flex justify-center items-center rounded-[12px] md:rounded-[13px] h-[58px] md:h-[69px] bg-white"
            />
            <div className="flex flex-col gap-[12px] lg:flex-row lg:gap-[20px] w-full">
              <InputField
                label="Email"
                name="email"
                type="email"
                asteriks={true}
                placeholder="e.g www.adejoko.com"
                className="w-full flex flex-col gap-2"
                inputClassName="w-full h-[58px] lg:h-[69px] rounded-[12px] lg:rounded-[13px]"
              />
              <InputField
                label="Contact"
                name="phoneNumber"
                type="number"
                asteriks={true}
                placeholder="e.g 23456789"
                className="w-full flex flex-col gap-2"
                inputClassName="w-full h-[58px] lg:h-[69px] rounded-[12px] lg:rounded-[13px]"
              />
            </div>
          </div>
          <div className="flex flex-col gap-[26px]">
            <h2 className="font-[500] text-[16px] leading-[30px] text-[#A3A3A3]">
              Service Information
            </h2>
            <InputField
              label="Description of work (Detailed)"
              name="work"
              type="text"
              asteriks={true}
              placeholder="Description of work done"
              className="w-full flex flex-col gap-2"
              inputClassName="w-full h-[58px] lg:h-[69px] rounded-[12px] lg:rounded-[13px]"
            />
            <div className="flex flex-col gap-[12px] lg:flex-row lg:gap-[20px] w-full">
              <InputField
                asteriks={true}
                label="Date Issued"
                type="calendar"
                name="date"
                value={dateValue}
                handleValue={setDateValue}
                placeholder="Select a date"
                onClick={handleDateClick}
                className="w-full "
              />

              <div className="flex flex-col gap-[7px] w-full">
                {/* Service */}
                <InputField
                  label="Service"
                  asteriks={true}
                  name="service"
                  type="select"
                  options={serviceOptions.filter(
                    (option) =>
                      !selectedOptions.find((sel) => sel.value === option)
                  )}
                  searchable={true}
                  placeholder="Select Service"
                  className="w-full flex flex-col gap-2"
                  selectClassName="w-full flex justify-center items-center rounded-[12px] md:rounded-[13px] h-[58px] md:h-[69px] bg-white"
                  handleValue={(value) => {
    console.log("Selected service:", value); // Debug the value here
    handleServiceValueChange(value);
  }}
                />
                {/* Display the selected value */}
                <div className="flex flex-wrap gap-[18px]">
                  {selectedOptions.map((option, index) => (
                    <div
                      key={index}
                      className="mt-[8px] flex items-center"
                    >
                      <div className="h-[39px] w-auto py-[10px] px-[10px] bg-[#DBE2EF] rounded-[12px] flex gap-[10px] items-center">
                        <p className="text-[15px] leading-[19px] text-black font-[500] font-montserrat">
                          {option}
                        </p>
                        <button
                          className="text-[17px]"
                          onClick={() => handleDeleteSelected(option)}
                        >
                          x
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

            </div>
            {selectedOptions.includes("Oil change") && (
  <InputField
    label="Oil Type"
    asteriks={true}
    name="oilType"
    type="select"
    value={selectedOilType}
    options={oilTypeOptions}
    searchable={true}
    placeholder="Select Oil Type"
    className="w-full flex flex-col gap-2"
    selectClassName="w-full flex justify-center items-center rounded-[12px] md:rounded-[13px] h-[58px] md:h-[69px] bg-white"
    handleValue={(value) => {
      console.log("Selected oil type:", value);
      setSelectedOilType(value);
    }}
  />
)}
            <div className="flex flex-col gap-[12px] lg:flex-row lg:gap-[20px] w-full">
              <InputField
                label="Amount paid"
                name="amount"
                type="text"
                asteriks={true}
                placeholder="e.g $100"
                className="w-full flex flex-col gap-2"
                inputClassName="w-full h-[58px] lg:h-[69px] rounded-[12px] lg:rounded-[13px]"
              />
       
              <InputField
                label="Payment Method"
                name="payment"
                type="select"
                asteriks={true}
                options={paymentOptions}
                placeholder="Select Payment"
                value={paymentMethod}
                handleValue={setPaymentMethod}
                className="w-full flex flex-col gap-2"
                selectClassName="w-full flex justify-center items-center rounded-[12px] md:rounded-[13px] h-[58px] md:h-[69px] bg-white"
              />
            </div>
            <div className="flex  items-center justify-center md:justify-around my-8 md:mt-[60px] md:mb-[100px]">
              <Button
                type="secondary"
                borderRadius="rounded-[5px]"
                size="S"
                label="Cancel"
                className="w-[119px] hidden md:block"
              />
              <Button
                type="tertiary"
                borderRadius="rounded-[5px]"
                size="S"
                label="Submit"
                className="md:w-[186px] w-full"
              />
            </div>
          </div>
        </form>
  </div>
  )
}

export default CreateCustomerInvoice