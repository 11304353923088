import React, { useRef, useState } from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import bolt from "../assets/images/bolt.png";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import OverviewBox from "../components/overviewBox";
import { Component as BarChart } from "../components/charts/OverviewBarChart";
import RecentServices from "../components/recentServices";
import TodayAppointment from "../components/todayAppointment";
import NewsInsights from "../components/newsInsights";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ReferHamzaa from "../components/ReferHamzaa";
import CompanyLogo from "../components/CompanyLogo";
import Button from "../components/button";
import Dropdown from "../components/dropdown";
import useClickOutside from "../utils/customhooks/useClickOutside";
import { useSelector } from "react-redux";

const Overview = ({ openScheduleModal, openReferralModal }) => {
  const merchant = useSelector((state) => state.auth.merchant);
  const firstName = merchant?.firstName;
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
  useClickOutside([dropdownRef, buttonRef], () => setIsDateDropdownOpen(false));
  const dateActions = [
    { label: "Today", onClick: () => console.log("Filter: Today") },
    { label: "This Week", onClick: () => console.log("Filter: This Week") },
    { label: "This Month", onClick: () => console.log("Filter: This Month") },
  ];
  const overviewData = [
    {
      id: 1,
      title: "All Appointments",
      value: 100,
      update: "+50%",
    },
    {
      id: 2,
      title: "Completed Appointments",
      value: 50,
      update: "-50%",
    },
    {
      id: 3,
      title: "Pending Appointments",
      value: 20,
      update: "-50%",
    },
    {
      id: 4,
      title: "Total Customers",
      value: 1000,
      update: "+50%",
    },
  ];

  const renderReferralItems = ({ openReferralModal }) => [
    // Slide 1: Refer on Hamzaa and Earn
    <ReferHamzaa openReferralModal={openReferralModal} />,
    // Slide 2: Company Logos
    <CompanyLogo />,
  ];

  const [referIndex, setReferIndex] = useState(0);

  const referCarouselRef = useRef(null);
  const nameReferCarouselRef = useRef(null);

  const syncReferCarousels = (e) => {
    setReferIndex(e.item);
    if (referCarouselRef.current) {
      referCarouselRef.current.slideTo(e.item);
    }
    if (nameReferCarouselRef.current) {
      nameReferCarouselRef.current.slideTo(e.item);
    }
  };
  const handleOpenScheduleAppointmentModal = () => {
    openScheduleModal();
  };
  const getGreeting = () => {
    const currentHour = new Date().getHours();

    if (currentHour >= 5 && currentHour < 12) {
      return "Good Morning";
    } else if (currentHour >= 12 && currentHour < 17) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };

  return (
     <HelmetProvider>
          <Helmet>
            <title>Hamzaa Merchant | Overview</title>
            <meta name="description" content="Hamzaa Overview page" />
          </Helmet>
    <div className="pt-[120px] lg:absolute top-[90px] z-10 px-6 lg:px-[48px] w-full lg:w-[calc(100%-319px)] scroll-smooth font-dmsans lg:pt-[60px] bg-white lg:bg-[#F8FAFF] lg:min-h-[100vh]">
      <div className="lg:flex lg:justify-between ">
        {/* Good Morning */}
        <div className="flex  gap-2 items-center ">
          <h1 className="text-primary font-[700] text-[20px] leading-[26px] lg:text-[32px] lg:leading-[42px] -tracking-[0.43px]">
          {getGreeting()}, {firstName}
          </h1>
          <img
            src={bolt}
            alt="bolt icon"
            className="w-[20px] h-[20px] lg:w-[24px] lg:h-[24px]"
          />
        </div>
        {/* Days dropdown and Add new customer button */}
        <div className="hidden lg:flex items-center gap-2 ">
          <div className="relative">
            <div ref={buttonRef}>
              <Button
                type="secondary"
                borderRadius="rounded-[5px]"
                size="XS"
                label="Last 7 days"
                className="w-auto text-[#1C1C1C] font-[400] text-[12px]"
                iconRight={
                  isDateDropdownOpen ? (
                    <IoIosArrowUp className="text-dropDownBg w-[20px] h-[20px] ml-2" />
                  ) : (
                    <IoIosArrowDown className="text-dropDownBg w-[20px] h-[20px] ml-2" />
                  )
                }
                onClick={() => setIsDateDropdownOpen((prev) => !prev)}
              />
            </div>
            {isDateDropdownOpen && (
              <div ref={dropdownRef}>
                <Dropdown
                  actions={dateActions}
                  onClose={() => setIsDateDropdownOpen(false)}
                  position="top-[60px]"
                  searchable={false}
                  width="w-full"
                />
              </div>
            )}
          </div>
          <Button
            onClick={handleOpenScheduleAppointmentModal}
            type="tertiary"
            borderRadius="rounded-[5px]"
            size="XS"
            label="Schedule Appointment"
            className="w-auto font-[500]"
            iconLeft="+"
            iconLeftClassName="text-[22px] leading-[21px]"
          />
        </div>
      </div>
      <div className="lg:hidden gap-4 flex md:flex-row md:flex-wrap flex-col mt-4 lg:mt-[32px] mb-[16px] w-full items-center">
        {overviewData.map((overview, index) => (
          <>
            <OverviewBox
              key={index}
              value={overview.value}
              title={overview.title}
              update={overview.update}
            />
          </>
        ))}
      </div>

      <div className="hidden border border-r-0 border-l-0 border-t-[#D9D9D9] border-b-[#D9D9D9] lg:flex gap-4  mt-4 p-4 lg:mt-[32px] mb-[24px] w-full items-center">
        {overviewData.map((overview, index) => (
          <div
            key={index}
            className={`flex-1  ${
              index !== overviewData.length - 1
                ? "border-r border-[#D9D9D9]"
                : ""
            }`}
          >
            <OverviewBox
              key={index}
              value={overview.value}
              title={overview.title}
              update={overview.update}
            />
          </div>
        ))}
      </div>
      {/* Service Type */}
      <div className="hidden lg:block mb-6 w-full">
        {/* Bar chart */}
        <div className="  border border-[#D9D9D9]  rounded-[4px] ">
          <BarChart />
        </div>
      </div>

      <div className="hidden md:flex justify-center items-center mb-[46px] lg:hidden">
        <AliceCarousel
          mouseTracking
          items={renderReferralItems({ openReferralModal })}
          controlsStrategy="responsive"
          keyboardNavigation={true}
          disableButtonsControls={true}
          autoPlay={true}
          autoPlayInterval={3000}
          activeIndex={referIndex}
          onSlideChanged={syncReferCarousels}
          ref={referCarouselRef}
          infinite={true}
          responsive={{
            0: { items: 1 }, // Show 1 combined item on smaller screens
            768: { items: 1 }, // Show 1 combined item on medium and larger screens
          }}
        />
      </div>
      <div className="w-full hidden md:flex  gap-4 mb-[40px]">
        <div className="w-[70%] h-full">
          {/* <Refer /> */}
          <RecentServices />
        </div>
        <div className="w-[30%] flex flex-col gap-4">
          <TodayAppointment />
          <NewsInsights />
        </div>
      </div>

      <div className="flex justify-center items-center mb-[46px] md:hidden">
        <AliceCarousel
          mouseTracking
          items={renderReferralItems({ openReferralModal })}
          controlsStrategy="responsive"
          keyboardNavigation={true}
          disableButtonsControls={true}
          autoPlay={true}
          autoPlayInterval={3000}
          activeIndex={referIndex}
          onSlideChanged={syncReferCarousels}
          ref={referCarouselRef}
          infinite={true}
          responsive={{
            0: { items: 1 }, // Show 1 combined item on smaller screens
            768: { items: 1 }, // Show 1 combined item on medium and larger screens
          }}
        />
      </div>
      <div className="block md:hidden mb-[40px]">
        <RecentServices />
      </div>
    </div>
   </HelmetProvider>
  );
};

export default Overview;
