import React, { useState, useEffect, useRef } from "react";
import { IoIosClose } from "react-icons/io";
import InputField from "./inputField";
import Button from "./button";
import ModalHeader from "./modalHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  editInventory,
  fetchCategories,
  fetchInventory,
} from "../store/inventorySlice";
import { FaCaretDown } from "react-icons/fa";
import { FaSpinner } from "react-icons/fa6";
import { toast } from "sonner";

const EditInventory = ({ closeModal, inventory }) => {
  const dispatch = useDispatch();
  const { categories, loading, error } = useSelector(
    (state) => state.inventory
  );
  const [name, setName] = useState("");
  const [quantityInStock, setQuantityInStock] = useState("");
  const [quantityOnOrder, setQuantityOnOrder] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [category, setCategory] = useState({ id: "", name: "" });
  // const [minStockLevel, setMinStockLevel] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  // Fetch categories on mount
  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    if (inventory) {
      setName(inventory.name || "");
      setQuantityInStock(inventory.quantityInStock || "");
      setQuantityOnOrder(inventory.quantityOnOrder || "");

      setSupplierName(inventory.supplierName || "");
      setCategory(
        inventory.category
          ? { id: inventory.category.id, name: inventory.category.name }
          : { id: "", name: "" }
      );
      // setMinStockLevel(inventory.minStockLevel || "");
    }
  }, [inventory]);

  // console.log("Inventory Data", inventory);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Start loading
    // Convert string inputs to numbers
    const quantityInStockNum = Number(quantityInStock);
    const quantityOnOrderNum = Number(quantityOnOrder);
    // const minStockLevelNum = Number(minStockLevel);

    // Validation: Ensure numbers are positive integers
    if (!Number.isInteger(quantityInStockNum) || quantityInStockNum <= 0) {
      toast.error("Quantity in Stock must be a positive integer.");
      setIsSubmitting(false);
      return;
    }

    if (!Number.isInteger(quantityOnOrderNum) || quantityOnOrderNum <= 0) {
      toast.error("Quantity on Order must be a positive integer.");
      setIsSubmitting(false);
      return;
    }
    // if (!Number.isInteger(minStockLevelNum) || minStockLevel <= 0) {
    //   toast.error("Min stock level must be a positive integer.");
    //   setIsSubmitting(false);
    //   return;
    // }

    if (!supplierName.trim()) {
      toast.error("Supplier Name is required.");
      setIsSubmitting(false);
      return;
    }
    // Call your async action
    try {
      await dispatch(
        editInventory({
          id: inventory?.id,
          name,
          quantityInStock: Number(quantityInStock),
          quantityOnOrder: Number(quantityOnOrder),
          // quantityInStock,
          // quantityOnOrder,
          supplierName,
          category: category.id,
        })
      ).unwrap();
      toast.success("Inventory updated successfully");
      dispatch(fetchInventory({page: 1}));
      closeModal();
    } catch (error) {
      console.error("Error updating inventory", error);
      toast.error(error.message || "Update inventory failed, please try again later.");
    } finally {
      setIsSubmitting(false); // Stop loading
    }
  };

  return (
    <div
      className="fixed inset-0 z-40 flex justify-center items-center md:items-start md:justify-end bg-black bg-opacity-40 font-dmsans"
      onClick={closeModal}
    >
      <div
        className="bg-white shadow-lg w-full md:w-[60vw] rounded-l-[10px] h-[90%] md:h-full overflow-y-auto  scrollbar-thin scrollbar-thumb-scrollbar-thumb hover:scrollbar-thumb-scrollbar-thumb-hover  scroll-smooth flex flex-col gap-8"
        onClick={(e) => e.stopPropagation()}
      >
        <ModalHeader
          header="Edit Inventory"
          onClick={closeModal}
          icon={<IoIosClose className="w-full h-full" />}
        />

        <form className="flex flex-col gap-[26px] px-6 md:px-[60px] w-full pt-[20px] md:mt-0 bg-white">
          <div className="flex flex-col gap-[12px] md:flex-row md:gap-[20px] w-full">
            <InputField
              label="Item Name"
              name="itemName"
              type="text"
              asteriks={true}
              placeholder="Brake Pads"
              className="w-full flex flex-col gap-2"
              inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px]"
              value={name}
              handleValue={setName}
            />
            <div className="flex flex-col gap-2 w-full">
              <label
                htmlFor="itemCategory"
                className="text-[14px] leading-[16px] md:leading-[18px] text-[#020202] mb-[4px] block"
              >
                Item Category
                <span className="text-[#FF0000]">*</span>
              </label>

              <div className="relative w-full">
                <button
                  ref={buttonRef}
                  type="button"
                  onClick={toggleDropdown}
                  className={` bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] text-primary placeholder-gray-400 placeholder:text-[14px] border border-[#D9D9D9] outline-primary focus:border-primary focus:border-2 w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px] flex justify-between items-center`}
                >
                  <span className=" text-[14px]">
                    {" "}
                    {category?.name || "Select Category"}
                  </span>

                  <FaCaretDown />
                </button>
                {isDropdownOpen && (
                  <div
                    ref={dropdownRef}
                    className="absolute mt-2 bg-white border border-gray-300 rounded-md shadow-lg w-full z-50"
                  >
                    {loading && (
                      <p className="text-center">Loading categories...</p>
                    )}
                    {error && (
                      <div className="flex flex-col gap-3 justify-center items-center">
                        <p>Error fetching Categories...</p>
                        <button
                          className="border-primary border p-2 rounded-md"
                          onClick={() => dispatch(fetchCategories())}
                        >
                          Retry
                        </button>
                      </div>
                    )}
                    <div className="w-full flex flex-col max-h-[300px] overflow-auto ">
                      {!loading && !error && categories.length > 0 ? (
                        categories.map((cat) => (
                          <button
                            key={cat.id}
                            onClick={() => {
                              setCategory({ id: cat.id, name: cat.name });
                              setIsDropdownOpen(false);
                            }}
                            className="bg-bgNeutral rounded-[4px] p-3 text-[500] text-primary text-[16px] hover:bg-[#FAFAFA] leading-[18px] -tracking-[3%] text-left text-nowrap"
                          >
                            {cat.name}
                          </button>
                        ))
                      ) : (
                        <p className="px-4 py-2 text-gray-500">
                          No categories available
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <InputField
              label="Item Category"
              name="itemCategory"
              type="text"
              asteriks={true}
              placeholder="Brake System"
              className="w-full flex flex-col gap-2"
              inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px]"

            /> */}
          </div>
          <div className="flex flex-col md:flex-row gap-[12px] md:gap-[20px] w-full">
            <InputField
              label="Quantity on Order"
              name="quantityOrder"
              type="text"
              asteriks={true}
              placeholder="BP12345"
              className="w-full flex flex-col gap-2"
              inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px]"
              value={quantityOnOrder}
              handleValue={(val) =>
                setQuantityOnOrder(val === "" ? "" : Number(val))
              }
            />
            <InputField
              label="Quantity in Stock"
              name="quantityStock"
              type="number"
              asteriks={true}
              placeholder="100"
              className="w-full flex flex-col gap-2"
              inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px]"
              value={quantityInStock}
              handleValue={(val) =>
                setQuantityInStock(val === "" ? "" : Number(val))
              }
            />
          </div>
          <div className="flex flex-col md:flex-row gap-[12px] md:gap-[20px] w-full">
            {/* <InputField
              label="Minimum Stock Level"
              name="minStockLevel"
              type="number"
              asteriks={true}
              placeholder="20"
              className="w-full flex flex-col gap-2"
              inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px]"
              value={minStockLevel}
              handleValue={(val) =>
                setMinStockLevel(val === "" ? "" : Number(val))
              }
              // handleValue={setMinStockLevel}
            /> */}
            <InputField
              label="Supplier Name"
              name="supplierName"
              type="text"
              asteriks={true}
              placeholder="ABC Supplies"
              className="w-full flex flex-col gap-2"
              inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px]"
              value={supplierName}
              handleValue={setSupplierName}
            />
          </div>
          <div className="flex  items-center justify-center md:justify-around my-8 md:mt-[60px] md:mb-[100px]">
            <Button
              type="secondary"
              borderRadius="rounded-[5px]"
              size="S"
              label="Cancel"
              className="w-[119px] hidden md:block"
              onClick={closeModal}
            />
            <Button
              type="tertiary"
              borderRadius="rounded-[5px]"
              size="S"
              label={
                isSubmitting ? (
                  <FaSpinner className="animate-spin mx-auto" />
                ) : (
                  "Submit"
                )
              }
              className="md:w-[186px] w-full"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditInventory;
