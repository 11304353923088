import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addCustomerModalOpen: false,
  addServiceModalOpen: false,
  scheduleAppointmentModalOpen: false,
  serviceDetailsModalOpen: false,
  addInventoryModalOpen: false,
  editAppointmentModalOpen: false,
  appointmentDetailsModalOpen: false,
  editInventoryModalOpen: false,
  navbarModalOpen: false,
  createInvoiceModalOpen: false,
  editCustomerModalOpen: false,
  vinDetailsModalOpen: false,
  referralModalOpen: false,
  invoiceDetailsModalOpen: false,
  planOrderReceiptModalOpen: false,
  upgradePlanModalOpen: false,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, action) => {
      state[action.payload] = true;
    },
    closeModal: (state, action) => {
      state[action.payload] = false;
    },
    closeAllModals: (state) => {
      Object.keys(state).forEach((key) => {
        state[key] = false;
      });
    },
  },
});

export const { openModal, closeModal, closeAllModals } = modalSlice.actions;
export default modalSlice.reducer;
