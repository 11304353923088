import React, { useState } from "react";
import { IoIosClose } from "react-icons/io";
// import clock from "../assets/images/clock.svg";
import InputField from "./inputField";
import Button from "./button";
import ModalHeader from "./modalHeader";
import { serviceOptions } from "../utils/dummyData/serviceOptions";

const ScheduleAppointmentModal = ({ closeModal }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [dateValue, setDateValue] = useState("");
  const [selectedOilType, setSelectedOilType] = useState("");

  const oilTypeOptions = ["Synthetic Oil", "Conventional Oil", "High-Mileage Oil", "Synthetic Blend Oil", "Diesel Engine Oil", "Turbocharged Engine Oil"];


  const handleServiceValueChange = (newValue) => {
    const selected = serviceOptions.find((option) => option === newValue);
    console.log("Adding selected option:", newValue);
    setSelectedOptions((prevSelected) => [...prevSelected, selected]);
  };
  

  const handleDeleteSelected = (valueToRemove) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.filter((option) => option !== valueToRemove)
    );
  };
  // const [timeValue, setTimeValue] = useState("");
  // const handleTimeClick = () => {
  //   document.getElementById("hiddenTimeInput").focus();
  // };


  return (
    <div
      className="fixed inset-0 z-40 flex justify-center items-center md:items-start md:justify-end bg-black bg-opacity-40 font-dmsans"
      onClick={closeModal}
    >
      <div
        className="bg-white shadow-lg w-full md:w-[60vw] rounded-l-[10px] h-[90%] md:h-full overflow-y-auto  scroll-smooth scrollbar-thin scrollbar-thumb-scrollbar-thumb hover:scrollbar-thumb-scrollbar-thumb-hover  flex flex-col gap-8"
        onClick={(e) => e.stopPropagation()}
      >
        <ModalHeader
          header="Schedule Appointment"
          onClick={closeModal}
          icon={<IoIosClose className="w-full h-full" />}
        />

        <form className="flex flex-col gap-[26px] px-6 md:px-[60px] w-full pt-[20px] md:mt-0 bg-white">
          <div className="flex flex-col gap-[7px] w-full">
            {/* Service */}
            <InputField
              label="Service"
              asteriks={true}
              name="service"
              type="select"
              options={serviceOptions.filter(
          (option) => !selectedOptions.includes(option)
        )}
              searchable={true}
              placeholder="Select Service"
              className="w-full flex flex-col gap-2"
              selectClassName="w-full flex justify-center items-center rounded-[12px] md:rounded-[13px] h-[58px] md:h-[69px]"
              handleValue={(value) => {
    console.log("Selected service:", value); // Debug the value here
    handleServiceValueChange(value);
  }}
            />
            {/* Display the selected value */}
            <div className="flex flex-wrap gap-[18px]">
              {selectedOptions.map((option, index) => (
                <div key={index} className="mt-[8px] flex items-center">
                  <div className="h-[39px] w-auto py-[10px] px-[10px] bg-[#DBE2EF] rounded-[12px] flex gap-[10px] items-center">
                    <p className="text-[15px] leading-[19px] text-black font-[500] font-montserrat">
                      {option}
                    </p>
                    <button
                      className="text-[17px]"
                      onClick={() => handleDeleteSelected(option)}
                    >
                      x
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {selectedOptions.includes("Oil change") && (
  <InputField
    label="Oil Type"
    asteriks={true}
    name="oilType"
    type="select"
    value={selectedOilType}
    options={oilTypeOptions}
    searchable={true}
    placeholder="Select Oil Type"
    className="w-full flex flex-col gap-2"
    selectClassName="w-full flex justify-center items-center rounded-[12px] md:rounded-[13px] h-[58px] md:h-[69px]"
    handleValue={(value) => {
      console.log("Selected oil type:", value);
      setSelectedOilType(value);
    }}
  />
)}

          <InputField
            label="Description"
            asteriks={true}
            name="businessDescription"
            type="textarea"
            placeholder="Recurring noise"
            className="w-full flex flex-col gap-2"
            inputClassName="w-full rounded-[12px] md:rounded-[13px]"
          />

          <InputField
            asteriks={true}
            label="Calendar"
            type="calendar"
            name="date"
            value={dateValue}
            handleValue={setDateValue}
            placeholder="Select a date"
            onClick={setDateValue}
          />

          <InputField
            label="Time"
            name="time"
            type="time"
            asteriks={true}
            placeholder="Pick a time"
            // postfix={<img src={clock} alt="Clock icon" className="w-5 h-5" />}
            className="w-full flex flex-col gap-2"
            inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px]"
            handleValue={(time) => console.log("Selected time:", time)}
          />

          <InputField
            label="Notes"
            asteriks={true}
            name="businessDescription"
            type="textarea"
            placeholder="For internal use of taking notes about the customer issues"
            className="w-full flex flex-col gap-2"
            inputClassName="w-full rounded-[12px] md:rounded-[13px] "
          />
          <div className="flex  items-center justify-center md:justify-around my-8 md:mt-[60px] md:mb-[100px]">
            <Button
              type="secondary"
              borderRadius="rounded-[5px]"
              size="S"
              label="Cancel"
              onClick={closeModal}
              className="w-[119px] hidden lg:flex justify-center items-center"
            />
            <Button
              type="tertiary"
              borderRadius="rounded-[5px]"
              size="S"
              label="Submit"
              className="md:w-[186px] w-full"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ScheduleAppointmentModal;
