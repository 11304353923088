import React, { useState, useRef } from "react";
import { FaCaretDown, FaEyeSlash } from "react-icons/fa";
import { GoEye } from "react-icons/go";
import Dropdown from "./dropdown";
import calendar from "../assets/images/calendar.svg";
import useClickOutside from "../utils/customhooks/useClickOutside";

const InputField = ({
  label,
  value,
  handleValue,
  placeholder,
  name,
  type,
  options = [],
  className = "",
  inputClassName = "",
  selectClassName = "",
  textareaClassName = "",
  prefix = null,
  disabled = false,
  onClick,
  postfix = null,
  onChange,
  onKeyDown,
  maxLength,
  asteriks = false,
  labelIcon,
  calendarClassName, 
  searchable,
  required=false,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dateInputRef = useRef(null);
const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  useClickOutside([dropdownRef, buttonRef], () => setIsDropdownOpen(false));
  
  const handleCalendarClick = () => {
    if (dateInputRef.current) {
      dateInputRef.current.showPicker(); // Opens the native date picker
    }
  };

 

  const togglePasswordVisibility = () =>
    setIsPasswordVisible(!isPasswordVisible);
  const handleChange = (e) => {
    const newValue = e.target.value;
  
    handleValue(
      type === "number"
        ? newValue === "" // Allow clearing the input
          ? ""
          : Math.max(0, parseInt(newValue) || 0) // Convert to integer
        : newValue
    );
  };
  
  const handleOptionClick = (option) => {
    handleValue(option || option.value);
    setIsDropdownOpen(false);
  };
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  return (
    <div className={`${className}`}>
	<div className="flex gap-[10px]">
      <label
        htmlFor={name}
        className="text-[14px] leading-[16px] md:leading-[18px] text-[#020202] mb-[4px] block"
      >
        {label}
		{asteriks && <span className="text-[#FF0000]">*</span>}
      </label>
	  <div>{labelIcon}</div>
	  </div>
	  {type === "calendar" ? (
        <div className="relative w-full">
          <button
            type="button"
            onClick={handleCalendarClick}
            className={`w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px] ${calendarClassName} bg-white py-2 px-[18px] border border-[#E7E7E7] outline-[#00325E] flex justify-between items-center ${
              disabled ? "cursor-not-allowed opacity-50" : ""
            }`}
            disabled={disabled}
          >
            {/* Display selected date or placeholder */}
            {value ? (
              <span>{value}</span>
            ) : (
              <span className="text-[#9E9E9E]">{placeholder || "Select a date"}</span>
            )}

            {/* Custom calendar icon */}
            <img src={calendar} alt="Calendar icon" className="w-5 h-5" />
          </button>

          {/* Hidden native date input */}
          <input
          required={required}
           ref={dateInputRef}
            type="date"
            id={name}
            value={value}
            onChange={onChange || ((e) => handleValue(e.target.value))}
            className="absolute top-10 right-[20px]  opacity-0 cursor-pointer"
          />
        </div>
      ) : type === "time" ? (
  <div className="relative w-full flex items-center">
    {/* {prefix && <span className="absolute left-3">{prefix}</span>} */}
    <input
     required={required}
      id={name}
      name={name}
      type="time"
      placeholder={placeholder}
      value={value}
      onChange={onChange || ((e) => handleValue(e.target.value))}
      className={`rounded-[10px] p-4 bg-white ${
        prefix ? "pl-14" : "px-[12px]"
      } text-[15px] font-[400] text-primary placeholder-[#949292] placeholder:text-[14px] border border-[#D9D9D9] focus:border-primary outline-primary ${inputClassName}`}
      disabled={disabled}
    />
    {/* {postfix && <span className="absolute right-3 ">{postfix}</span>} */}
  </div>
) :
      type === "select" ? (
        <div className="relative w-full">
          <button
          ref={buttonRef}
            type="button"
            onClick={toggleDropdown}
            className={`flex justify-between bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] text-primary placeholder-gray-400 placeholder:text-[14px] border border-[#D9D9D9] outline-primary focus:border-primary focus:border-2 ${selectClassName} ${
              disabled && "cursor-not-allowed opacity-50"
            }`}
            disabled={disabled}
          >
          <div className="flex gap-2 items-center">
          {prefix && prefix}
            <p>
            {options.find((opt) => opt === value) || 
     options.find((opt) => opt.value === value)?.label || 
     placeholder}
            </p>
            </div>
            <FaCaretDown />
          </button>
          {isDropdownOpen && (
            <div ref={dropdownRef}>
            <Dropdown
             actions={options.map((opt) =>
            typeof opt === "string"
              ? { label: opt, onClick: () => handleOptionClick(opt) }
              : { label: opt.label, onClick: () => handleOptionClick(opt.value) }
          )}
              onClose={() => setIsDropdownOpen(false)}
              position="top-15"
              width="w-full"
              searchable={searchable}
            />
            </div>
          )}
        </div>
      ) : type === "textarea" ? (
        <textarea
          id={name}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange || handleChange}
          className={`w-full min-h-[120px] h-[56px] rounded-[10px] p-4 bg-white py-[14px] px-[12px] text-[15px] font-[400] text-primary placeholder-[#949292] placeholder:text-[14px] border border-[#D9D9D9] focus:border-primary outline-primary  ${textareaClassName}`}
          disabled={disabled}
        />
      ) : (
        <div className="relative w-full flex items-center">
          {prefix && <span className="absolute left-3">{prefix}</span>}
          <input
           required={required}
            id={name}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange || ((e) => handleValue(e.target.value))}
            type={
              type === "password"
                ? isPasswordVisible
                  ? "text"
                  : "password"
                : type
            }
            className={`rounded-[10px] p-4 bg-white  ${
              prefix ? "pl-14" : "px-[12px]"
            } text-[15px] font-[400] text-primary placeholder-[#949292] placeholder:text-[14px] border border-[#D9D9D9] focus:border-primary outline-primary ${inputClassName}`}
            disabled={disabled}
            onClick={onClick}
            onKeyDown={onKeyDown}
            maxLength={maxLength}
            autoComplete="off"
            onWheel={(e) => type === 'number' && e.target.blur()} 
          />
          {postfix && <span className="absolute right-3">{postfix}</span>}
          {type === "password" && (
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute right-3"
            >
              {isPasswordVisible ? (
                <FaEyeSlash size={20} />
              ) : (
                <GoEye size={20} />
              )}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default InputField;
