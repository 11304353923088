import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import customers from "../utils/dummyData/customerData";
import { FaEllipsisVertical } from "react-icons/fa6";
import Button from "./button";
import useClickOutside from "../utils/customhooks/useClickOutside";
import { useDispatch, useSelector } from "react-redux";
import { deleteCustomer, fetchCustomerById } from "../store/customerSlice";
import { FaSpinner } from "react-icons/fa6";
import { toast } from "sonner";

const CustomerDetails = ({
  openScheduleModal,
  openCreateInvoiceModal,
  openEditCustomerModal,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [dropDown, setDropDown] = useState(false);
  const { customers, loading, error } = useSelector((state) => state.customer);
  const customer = customers?.find((customer) => customer.id === Number(id));
  console.log("Customer Details", customer);

  useEffect(() => {
    if (!customer && id) {
      dispatch(fetchCustomerById(id)); // Fetch customers when the component mounts
    }
  }, [dispatch, customer, id, loading]);
  // const customer = customers?.find((customer) => customer.id === Number(id));
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  useClickOutside([dropdownRef, buttonRef], () => setDropDown(false));
  const handleScheduleAppointmentModalOpen = () => openScheduleModal();

  const handleCreateInvoiceModalOpen = () => openCreateInvoiceModal();
  const handleDropDown = () => {
    setDropDown(!dropDown);
  };
  const handleCustomerDetails = () => {
    navigate(`/customers/list/details/${customer?.id}/service-history`);
  };
  const handleDeleteCustomer = async () => {
    if (!customer?.id) {
      toast.error("Customer ID is missing.");
      return;
    }
  
    try {
      const response = dispatch(deleteCustomer({ id: customer.id }));
  
      // Ensure response has expected structure before accessing properties
      if (response?.payload?.status === "success") {
        toast.success(response.payload.message || "Customer deleted successfully");
        navigate('/customers/list');
      } else {
        throw new Error(response?.payload?.message || "Delete failed");
      }
    } catch (error) {
      console.error("Error deleting customer", error);
      toast.error(error?.message || "Delete customer failed, please try again later.");
    }
  };
  

  if (loading) {
    return   <div className="absolute top-[90px] px-6 lg:px-[48px] z-10 w-full scroll-smooth font-dmsans pt-[60px] bg-white lg:bg-[#F8FAFF] min-h-[100vh]"><FaSpinner className="animate-spin mx-auto lg:mx-0" /></div>; 
  }

  if (error) {
    return   <div className="absolute top-[90px] z-10 px-[48px] w-full scroll-smooth font-dmsans pt-[60px] bg-white lg:bg-[#F8FAFF] min-h-[100vh]"><div className="flex justify-center items-center">{error}</div></div>;
  }

  if (!customer) {
    return (
      <div className="absolute top-[90px] z-10 px-[48px] w-full scroll-smooth font-dmsans pt-[60px] bg-white lg:bg-[#F8FAFF] min-h-[100vh]">
        Customer not found
      </div>
    );
  }
 
  return (
    <div className=" lg:absolute lg:top-[20px] z-10 lg:pr-[283px] px-6 lg:px-[48px] w-full lg:w-[calc(100%-319px)] scroll-smooth font-dmsans pt-4 lg:pt-[60px] bg-white lg:bg-[#F8FAFF] min-h-[100vh]">
      <div className="relative mt-[100px] flex flex-col lg:flex-row lg:justify-between lg:border border-[#D9D9D9] rounded-[10px] w-full lg:w-[50vw] lg:p-8 lg:min-h-[600px] mb-[30px]">
        <div className="lg:hidden flex gap-[14px] items-center mb-[40px]">
          <Button
            type="tertiary"
            borderRadius="rounded-[5px]"
            size="XS"
            label=" Schedule Appointment"
            className="w-auto "
            onClick={handleScheduleAppointmentModalOpen}
          />

          <Button
            type="secondary"
            borderRadius="rounded-[6px]"
            size="XS"
            label="Service History"
            className="w-auto  h-[48px]"
            onClick={handleCustomerDetails}
          />
          <button onClick={handleDropDown} className="ml-1">
            <FaEllipsisVertical className="text-primary" />
          </button>
        </div>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <h1 className="font-[700] text-[20px] leading-[32px] text-[#273240]">
              Personal Information
            </h1>

            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Name:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                {customer?.name}
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Email:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                {customer?.email}
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Phone Number:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                {customer?.phone}
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Address:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                {customer?.address}
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Date Created:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                {customer?.createdAt
                  ? new Intl.DateTimeFormat("en-GB", {
                      year: "numeric",
                      month: "short",
                      day: "2-digit",
                    }).format(new Date(customer.createdAt))
                  : "N/A"}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <h1 className="font-[700] text-[20px] leading-[32px] text-[#273240]">
              Car Details
            </h1>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Make:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                {customer?.carMake}
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Model:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                {customer?.carModel}
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Year:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                ({customer?.year})
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Plate Number:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                {customer?.plateNumber}
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Mileage:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                {customer?.mileage}
              </p>
            </div>
          </div>
        </div>
        <div className="hidden lg:flex flex-col items-center gap-4">
          <Button
            type="tertiary"
            borderRadius="rounded-[5px]"
            size="XS"
            label=" Schedule Appointment"
            className="lg:w-auto w-full"
            onClick={handleScheduleAppointmentModalOpen}
          />

          <Button
            type="secondary"
            borderRadius="rounded-[6px]"
            size="XS"
            label="Service History"
            className="w-auto hidden lg:block h-[48px]"
            onClick={handleCustomerDetails}
          />
          <Button
            type="secondary"
            borderRadius="rounded-[6px]"
            size="XS"
            label="Create Invoice"
            className="w-auto hidden lg:block h-[48px]"
            onClick={handleCreateInvoiceModalOpen}
          />
        </div>
        <div>
          <button
            ref={buttonRef}
            onClick={handleDropDown}
            className="lg:flex hidden"
          >
            <FaEllipsisVertical className="text-primary" />
          </button>
        </div>
        {dropDown && (
          <div
            ref={dropdownRef}
            className="absolute right-0 top-[100px] md:right-[420px] lg:right-[21px] w-[117px] h-[124px] rounded-[8px] p-4 bg-[#FAFAFA] shadow-sm border border-[#D9D9D9] flex flex-col items-start justify-center gap-4"
          >
            <button
              onClick={() => openEditCustomerModal(customer)}
              className="font-[400] text-[12px] leading-[20px] text-[#273240]"
            >
              Edit
            </button>
            <button className="font-[400] text-[12px] leading-[20px] text-[#273240] w-[94px] h-[20px]">
              Create Reminder
            </button>
            <button onClick={ handleDeleteCustomer} className="font-[400] text-[12px] leading-[20px] text-[#EC5252]">
              Delete
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerDetails;
