import React, { useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { toast } from "sonner";
import InputField from "../components/inputField";
import AuthHeader from "../components/authHeader";
import mail from "../assets/images/mail.svg";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../store/authSlice";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const handleSubmit = async(e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
     await dispatch(forgotPassword(email)).unwrap();
      toast.success("Password reset link sent");
    } catch (error) {
      toast.info(error || "Password reset failed, please try again later."); // Assuming API provides detailed error messages
    } finally {
      setIsLoading(false); // Always stop loading state, whether success or error
    }
  };
  // const [otp, setOtp] = useState(new Array(4).fill(''));

  // const handleChange = (e, index) => {
  //   const { value } = e.target;

  //   // Only allow numbers, and update state if a single digit is entered
  //   if (/^[0-9]?$/.test(value)) {
  //     const newOtp = [...otp];
  //     newOtp[index] = value;
  //     setOtp(newOtp);

  //     // Move to the next input if the current one is filled
  //     if (value && index < otp.length - 1) {
  //       e.target.nextSibling.focus();
  //     }
  //   }
  // };

  // const handleBackspace = (e, index) => {
  //   if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
  //     e.target.previousSibling.focus();
  //   }
  // };
  return (
    <HelmetProvider>
      <Helmet>
        <title>Hamzaa Forgot Password</title>
        <meta name="description" content="Hamzaa Forgot Password page" />
      </Helmet>
      <div className="flex w-full min-h-[100vh] font-dmsans">
        {/* Background image container */}
        <div className="fixed top-0 left-0 md:w-[30vw] h-[100vh] bg-signin-image bg-cover bg-no-repeat  hidden md:flex justify-end">
          {/* Content box aligned at the bottom */}
          <div className="hidden absolute bottom-5 left-5 w-[20vw]  min-h-[35vh]  border-[2px] bg-signup-gradient backdrop-blur-[40px] border-[#F5F5F533]  rounded-[20px] md:flex justify-center items-center p-[20px] lg:p-[45px] text-white font-[400] text-[16px] leading-[24px] 2xl:text-[20px] 2xl:leading-[28px]">
            “I really appreciate the scheduling feature, and the reminders keep
            me updated on my vehicle's status, helping me avoid unexpected
            emergencies."
          </div>
        </div>
        <div className="md:absolute w-full mt-[50px] md:mt-0 md:ml-[30%] md:w-[70%] flex flex-col px-6 md:p-[80px] overflow-hidden">
          <AuthHeader />
          <div className="flex flex-col gap-[6px] mt-[60px] mb-[100px]">
            <h2 className="font-spaceGrotesk font-[500] text-[24px] leading-[40px] md:leading-[52px] tracking-[0.3px] text-[#1C1C1C]">
              Forgot Password
            </h2>
            <p className="font-[400] text-[12px] md:text-[16px] leading-[18px] md:leading-[28px] tracking-[0.09px] text-lightGreyBg">
              Password reset link will be sent to{" "}
              <span className="text-[#FD6B22]">your email</span>
            </p>
          </div>
          <form
            onSubmit={handleSubmit}
            className="w-full md:w-[80%] flex flex-col justify-center items-center px-6 md:px-0"
          >
            <InputField
              required={true}
              label="Email"
              name="Email"
              type="email"
              placeholder="Enter your email"
              postfix={
                <img
                  src={mail}
                  alt="Email icon"
                  className="w-[20px] h-[16px]"
                />
              }
              className="w-full flex flex-col gap-2"
              inputClassName="w-full h-[60px] md:h-[56px] rounded-[10px]"
              value={email}
              handleValue={setEmail}
              asteriks={true}
            />
            {/* <div className='flex justify-center items-center gap-[13px]'>
        {otp.map((data, index) => (
          <InputField  
          key={index}
          type="text"
          maxLength="1"
          value={data}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleBackspace(e, index)}
          inputClassName='w-12 h-[62px] rounded-[8px] text-center rounded-[8px]  font-[700] text-[36px] leading-[46px] text-[#1B1D21] focus:outline-none focus:ring-2 '
           />
      ))}
      </div> */}
            {/* <div className='mt-[48px]'>
      <p className='font-[300] text-[12px] leading-[24px] tracking-[0.3px] text-lightGreyBg'>Code Sent. Resend Code in <span className='text-[#FD6B22]'>00:50</span></p>
      </div> */}
            <div className="flex justify-center items-center w-full mt-[48px] mb-[20px]">
              <button
                disabled={isLoading}
                className={` ${isLoading ? "cursor-not-allowed opacity-50" : "cursor-pointer"
                } md:mt-[28px] w-full h-[52px] md:h-[60px] border-[2px] rounded-[8px] py-[18px] px-[18px] flex justify-center items-center bg-[#007BD1] border-[#0A0A0A] font-[700] text-[16px] leading-[24px] text-center text-white`}
              >
                {isLoading ? "Sending..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </HelmetProvider>
  );
};

export default ForgotPassword;
